import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { EmbeddedResponse, GameFull, GameStatus, UserSummary } from "./useApplicationApi";
import { disableMatchCacheMutator, RegistrationSummary } from "./useTournamentApi";
import { Maybe } from "../TYPE";

export function useStageApi() {
   const instance = useAxiosInstance();

   const api = {
      createStage: useRequest<StageSummaryResponse, never, SaveStageRequest, never>(
         useMemo(
            () => ({
               url: "/stages/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateStage: useRequest<StageSummaryResponse, { id: string }, SaveStageRequest, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      getSeededRegistrations: useRequest<
         EmbeddedResponse<{ registrations: RegistrationSummary[] }>,
         {
            id: string;
         },
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/stages/{id}/seededRegistrations?projection=summary",
               method: "get",
            }),
            []
         ),
         instance
      ),
      removeSeedPlayer: useRequest<StageSummaryResponse, { id: string; registrationId: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/seededRegistrations/{registrationId}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      autoSeed: useRequest<StageSummaryResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/autoSeed/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      addRound: useRequest<StageSummaryResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/rounds/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      seedPlayer: useRequest<StageSummaryResponse, { id: string }, SeedPlayerRequest, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/seededRegistrations/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      activateStage: useRequest<StageSummaryResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/activate/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      closeStage: useRequest<StageSummaryResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/close/",
               method: "post",
            }),
            []
         ),
         instance
      ),
      deleteStage: useRequest<never, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/delete/",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      getStageFull: useRequest<StageFullResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}?projection=full",
               method: "get",
               configMutator: disableMatchCacheMutator,
            }),
            []
         ),
         instance
      ),
      getPreviousStage: useRequest<StageSummaryResponse, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/stages/{id}/previousStage?projection=summary",
               method: "get",
               configMutator: disableMatchCacheMutator,
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface SeedPlayerRequest {
   registrationId: string;
}

export interface UnstructuredLeagueSettings {
   allowPlayerSubmissions: boolean;
   winPoints: number;
   lossPoints: number;
   drawPoints: number;
   secondPlacePoints: number;
   thirdPlacePoints: number;
   fourthPlacePoints: number;
   matchWinPercentageFloor: number;
   tiebreakerSystem: TieBreakerSystem;
}

export type AutoPairStrategy =
   | "RANDOM"
   | "ELO_BASED_NON_GROUPED"
   | "ELO_BASED_GROUPED"
   | "MAMTR_SWISS_PAIRING"
   | "STANDINGS_BASED_GROUPED"
   | "STANDINGS_PREVIOUS_STAGE_GROUPED"
   | "STANDINGS_PREVIOUS_STAGE_NON_GROUPED"
   | "MAMTR_ELIMINATION_PAIRING";

export type PodOrderStrategy = "RANDOM" | "PREVIOUS_STAGE_STANDINGS" | "OVERALL_TOURNAMENT_STANDINGS";

export function getUserFriendlyNameForPodOrder(podOrder: PodOrderStrategy) {
   switch (podOrder) {
      case "RANDOM":
         return "Random";
      case "OVERALL_TOURNAMENT_STANDINGS":
         return "Overall tournament standings";
      case "PREVIOUS_STAGE_STANDINGS":
         return "Previous stage standings";
   }
}

export function getUserFriendlyNameForByeAllocator(allocator: ByeAllocationStrategy) {
   switch (allocator) {
      case "MSTR_COMPETITIVE_REL":
         return "Increased byes - Max 3 byes - 4 player pods only";
      case "MSTR_REGULAR_REL":
         return "Balanced - Max 2 byes - Max single 3 player pod";
      case "REDUCED_BYES":
         return "Reduced byes - No byes - Max 3 pods of 3 players";
      case "MAMTR":
         return "MAMTR - No byes - Max 3 pods of 3 players - Avoid repeated pods";
   }
}

export function getUserFriendlyNameForTieBreakers(tiBreaker: TieBreakerSystem) {
   switch (tiBreaker) {
      case "BRAINSTORM_WBE":
         return "Brainstorm WBE";
      case "MTR_3_1":
         return "MTR 3.1";
      case "MAMTR_3_1":
         return "MAMTR 3.1";
      case "MTRA_3_1":
         return "MTRA 3.1";
   }
}

export function getUserFriendlyNameForAutoPair(autoPairStrat: AutoPairStrategy) {
   switch (autoPairStrat) {
      case "RANDOM":
         return "Random";
      case "ELO_BASED_GROUPED":
         return "Elo based - Quad grouping";
      case "ELO_BASED_NON_GROUPED":
         return "Elo based - Single grouping";
      case "STANDINGS_BASED_GROUPED":
         return "Standings based - Quad grouping";
      case "MAMTR_SWISS_PAIRING":
         return "MAMTR Swiss pairing";
      case "STANDINGS_PREVIOUS_STAGE_GROUPED":
         return "Standings based - Previous stage - Quad grouping";
      case "STANDINGS_PREVIOUS_STAGE_NON_GROUPED":
         return "Standings based - Previous stage - Single grouping";
      case "MAMTR_ELIMINATION_PAIRING":
         return "MAMTR Elimination pairing";
   }
}

export interface SwissStageSettings {
   winPoints: number;
   lossPoints: number;
   drawPoints: number;
   byePoints: number;
   secondPlacePoints: number;
   thirdPlacePoints: number;
   fourthPlacePoints: number;
   tiebreakerSystem: TieBreakerSystem;
   byeAllocationStrategy: ByeAllocationStrategy;
   matchWinPercentageFloor: number;
}

export type TieBreakerSystem = "BRAINSTORM_WBE" | "MTR_3_1" | "MTRA_3_1" | "MAMTR_3_1";

export type ByeAllocationStrategy = "MSTR_REGULAR_REL" | "MSTR_COMPETITIVE_REL" | "REDUCED_BYES" | "MAMTR";

export interface SingleEliminationStageSettings {}

export interface SaveStageRequest {
   tournamentId: string;
   name: string;
   autoQualifyEnabled: boolean;
   podOrderingStrategy: PodOrderStrategy;
   autoPairStrategy: AutoPairStrategy;
   unstructuredLeagueSettings?: Maybe<UnstructuredLeagueSettings>;
   playerCountLimit: number;
   swissSettings?: Maybe<SwissStageSettings>;
   singleEliminationSettings?: Maybe<SingleEliminationStageSettings>;
}

export interface StageSummaryResponse {
   id: string;
   name: string;
   status: StageRoundStatus;
   canModifyRounds: boolean;
   stageType: StageType;
}

export interface SwissStage extends PointsProvider {
   byePoints: number;
}

export interface PointsProvider {
   winPoints: number;
   lossPoints: number;
   seedCountLimit: number;
   drawPoints: number;
   tiebreakerSystem: TieBreakerSystem;
   matchWinPercentageFloor: number;
   secondPlacePoints: number;
   thirdPlacePoints: number;
   fourthPlacePoints: number;
}

export interface SingleEliminationStage {}

export interface StageFullResponse extends StageSummaryResponse {
   rounds: RoundFullResponse[];
   unstructuredLeagueStage?: UnstructuredLeagueStageResponse;
   swissLeagueStage?: SwissStage;
   singleEliminationStage?: SingleEliminationStage;
   autoPairStrategy: AutoPairStrategy;
   isAutoQualifyEnabled: boolean;
   podOrderingStrategy: PodOrderStrategy;
   previousStage?: StageSummaryResponse;
   byeAllocationStrategy: ByeAllocationStrategy;
   playerCountLimit: number;
   isUserQualified: boolean;
   standings: StandingsRegResponse[];
   supportedResultOverrides: Result[];
}

export interface UnstructuredLeagueStageResponse extends PointsProvider {
   allowPlayerSubmissions: boolean;
}

export interface RoundFullResponse {
   id: string;
   roundNumber: number;
   status: StageRoundStatus;
   roundEndDate: Maybe<string>;
   matches: MatchResultWinnerOnlyResponse[];
}

export interface MatchFullResponse extends MatchResultWinnerOnlyResponse {
   games: GameFull[];
}

export interface MatchResultWinnerOnlyResponse {
   id: string;
   matchNumber: number;
   matchStatus: GameStatus;
   winningPlayer: Maybe<UserSummary>;
   resultOverrides: MatchOverrideSummary[];
   matchResults: MatchResult[];
}

export interface MatchResult {
   registration: RegistrationSummary;
   result: Result;
}

export interface MatchOverrideSummary {
   registration: RegistrationSummary;
   result: Result;
   matchId: string;
}

export interface SaveOverrideRequest {
   registrationId: string;
   overrideResultAs: Result;
}

export type Result = "DRAW" | "FIRST" | "SECOND" | "THIRD" | "FOURTH" | "LOSS";

export const singletonResults: Result[] = ["FIRST", "SECOND", "THIRD", "FOURTH"];

export interface StandingsRegResponse {
   registration: RegistrationSummary;
   standing: Standing;
}

export interface Standing {
   wins: number;
   draws: number;
   losses: number;
   pointsTotal: number;
   byes: number;
   buchholzScore: number;
   elo: number;
   gameWins: number;
   gameDraws: number;
   gameLosses: number;
   playerMatchWinPercentage: number;
   playerGameWinPercentage: number;
   opponentMatchWinPercentage: number;
   opponentMatchWinPercentageInclByes: number;
   opponentsAverageMatchPoints: number;
   opponentGameWinPercentage: number;
   status: PlayerStageStatus;
}

export const EMPTY_STANDING: Standing = {
   wins: 0,
   draws: 0,
   losses: 0,
   pointsTotal: 0,
   byes: 0,
   buchholzScore: 0,
   elo: 0,
   gameWins: 0,
   gameDraws: 0,
   gameLosses: 0,
   playerMatchWinPercentage: 0,
   playerGameWinPercentage: 0,
   opponentMatchWinPercentage: 0,
   opponentMatchWinPercentageInclByes: 0,
   opponentsAverageMatchPoints: 0,
   opponentGameWinPercentage: 0,
   status: "ACTIVE",
};

export type PlayerStageStatus = "ELIMINATED" | "ACTIVE" | "DROPPED";

export type StageRoundStatus = "SCHEDULED" | "ACTIVE" | "CLOSED";

export type StageType = "UNSTRUCTURED_LEAGUE" | "SWISS" | "SINGLE_ELIMINATION";
