import React from "react";
import { Avatar, Box, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { DecklistSourceType } from "../../rest.client/useDeckListApi";
import { Consumer } from "../../utils";
import EditIcon from "@mui/icons-material/Edit";

export default function DecklistSourceSelector({
   type,
   setType,
   disabled,
}: {
   type: DecklistSourceType;
   setType: Consumer<DecklistSourceType>;
   disabled: boolean;
}) {
   const theme = useTheme();

   return (
      <TextField
         sx={{ marginTop: theme.spacing(0) }}
         fullWidth
         required
         select
         disabled={disabled}
         size={"small"}
         variant={"outlined"}
         label={"Decklist source"}
         value={type}
         onChange={(e) => {
            const selection = e.target.value as DecklistSourceType;
            setType(selection);
         }}
      >
         <MenuItem key={""} value={"MOXFIELD"}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <Avatar style={{ height: 24, width: 24 }} src={"/moxfield_icon.png"} alt={"open in moxfield icon"} />
               <Typography>Moxfield</Typography>
            </Box>
         </MenuItem>
         <MenuItem key={""} value={"TEXT"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <EditIcon />
               <Typography sx={{ marginLeft: theme.spacing(1) }}>Text</Typography>
            </Box>
         </MenuItem>
      </TextField>
   );
}
