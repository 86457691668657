import React from "react";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   MenuItem,
   TextField,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { RemoteTournament } from "../useRemoteTournament";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import useReload from "../../header/useReload";
import useRemoteRulesInfraction from "./useRemoteRulesInfraction";
import RegistrationSelector from "../viewPage/matches/editDialog/RegistrationSelector";
import RulesTypeSelector from "./RulesTypeSelector";
import { Penalty, RulesInfractionSummary } from "../../rest.client/useRulesInfractionApi";
import { Maybe } from "../../TYPE";
import RulesInfractionMenu from "./RulesInfractionMenu";
import { Consumer } from "../../utils";

const CreateOrEditRulesInfractionDialog: React.FC<{
   open: boolean;
   readOnly: boolean;
   remoteTournament: RemoteTournament;
   closeHandler: () => void;
   existingRulesInfraction?: Maybe<RulesInfractionSummary>;
   onClickEdit: Consumer<RulesInfractionSummary>;
}> = ({ open, closeHandler, readOnly, remoteTournament, existingRulesInfraction, onClickEdit }) => {
   const theme = useTheme();
   const addMessageItem = useAddMessageItem();
   const reload = useReload("rulesInfractions");
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const remoteRulesInfraction = useRemoteRulesInfraction(remoteTournament, existingRulesInfraction || undefined);

   return (
      <Dialog
         id={"tournamentRegistrationDrawer"}
         open={open}
         fullScreen={smallScreen}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"sm"}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               closeHandler();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">
            <Box sx={{ display: "flex", gap: theme.spacing(1), justifyContent: "space-between" }}>
               <Typography>{readOnly ? "View rules infraction" : "Save rules infraction"}</Typography>
               {existingRulesInfraction && (
                  <RulesInfractionMenu rulesInfraction={existingRulesInfraction} onClickEdit={onClickEdit} />
               )}
            </Box>
         </DialogTitle>
         <DialogContent>
            <Box>
               <RegistrationSelector
                  reg={remoteRulesInfraction.rulesInfractionData.registration}
                  setRegistration={remoteRulesInfraction.setRegistration}
                  acceptedOnly={false}
                  registrations={remoteTournament.tournament?.registrations || []}
                  readOnly={readOnly || existingRulesInfraction != null}
                  fullWidth={true}
                  selectSx={{ width: "inherit" }}
                  inUseRegs={[]}
               />
               <RulesTypeSelector
                  readOnly={readOnly}
                  selectedType={remoteRulesInfraction.rulesInfractionData.rulesInfractionType}
                  setSelectedType={remoteRulesInfraction.setRulesInfractionType}
               />
               <Box sx={{ display: "flex", padding: theme.spacing(1) }}>
                  <TextField
                     fullWidth
                     required
                     select
                     disabled={readOnly}
                     variant={"outlined"}
                     label={"Penalty"}
                     value={remoteRulesInfraction.rulesInfractionData.penalty}
                     onChange={(e) => {
                        const selection = e.target.value as Penalty;
                        remoteRulesInfraction.setPenalty(selection);
                     }}
                  >
                     <MenuItem key={""} value={"NONE"}>
                        NONE
                     </MenuItem>
                     <MenuItem key={""} value={"WARNING"}>
                        WARNING
                     </MenuItem>
                     <MenuItem key={""} value={"GAME_LOSS"}>
                        GAME_LOSS
                     </MenuItem>
                     <MenuItem key={""} value={"MATCH_LOSS"}>
                        MATCH_LOSS
                     </MenuItem>
                     <MenuItem key={""} value={"DISQUALIFICATION"}>
                        DISQUALIFICATION
                     </MenuItem>
                  </TextField>
               </Box>
               <Box sx={{ display: "flex", padding: theme.spacing(1) }}>
                  <TextField
                     fullWidth
                     variant={"outlined"}
                     disabled={readOnly}
                     multiline={true}
                     label={"Description"}
                     value={remoteRulesInfraction.rulesInfractionData.description || ""}
                     onChange={(e) => {
                        const selection = e.target.value;
                        remoteRulesInfraction.setDescription(selection);
                     }}
                  ></TextField>
               </Box>
            </Box>
         </DialogContent>
         <DialogActions>
            {!readOnly && (
               <Button
                  variant={"contained"}
                  onClick={async () => {
                     await remoteRulesInfraction.saveRulesInfraction();
                     addMessageItem("Infraction saved!", "success");
                     reload.requestReload();
                     closeHandler();
                  }}
                  disabled={
                     remoteRulesInfraction.rulesInfractionData.rulesInfractionType == null ||
                     remoteRulesInfraction.rulesInfractionData.registration == null
                  }
               >
                  Ok
               </Button>
            )}
            <Button variant={"outlined"} onClick={closeHandler}>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default CreateOrEditRulesInfractionDialog;
