import React, { ReactNode } from "react";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function HelpIcon({ message }: { message: NonNullable<ReactNode> }) {
   return (
      <Tooltip title={message} placement="top" leaveTouchDelay={3000} enterTouchDelay={50}>
         <HelpOutlineIcon color="secondary" sx={{ cursor: "pointer" }} onClick={(e) => e.stopPropagation()} />
      </Tooltip>
   );
}
