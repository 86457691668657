import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme } from "@mui/material";
import { useTournamentApi } from "../../rest.client/useTournamentApi";
import { Procedure } from "../../utils";
import { useNavigate } from "react-router-dom";
import { toTournamentDetails } from "../../navPaths";

export default function CreateTestTournamentDialog({ open, onClose }: { open: boolean; onClose: Procedure }) {
   const theme = useTheme();
   const nav = useNavigate();
   const [name, setName] = useState<string>("");
   const [playerCount, setPlayerCount] = useState(16);
   const {
      createTestTournament: { call: createTestTournament },
   } = useTournamentApi();

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"sm"}
         fullWidth={true}
         sx={{ minWidth: "200px" }}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Submit result</DialogTitle>
         <DialogContent>
            <Box sx={{ padding: theme.spacing(1), display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
               <TextField
                  required={true}
                  fullWidth={true}
                  label={"name"}
                  value={name}
                  onChange={(v) => setName(v.target.value)}
               />
               <TextField
                  fullWidth={true}
                  label={"Player count"}
                  value={playerCount}
                  type={"number"}
                  onChange={(v) => setPlayerCount(parseInt(v.target.value))}
               />
            </Box>
         </DialogContent>
         <DialogActions>
            <Button
               color={"secondary"}
               disabled={name == null || name.length === 0}
               onClick={async () => {
                  const tournament = await createTestTournament({ body: { name, playerCount } });
                  onClose();
                  nav(toTournamentDetails(tournament.id));
               }}
            >
               Create
            </Button>
            <Button
               onClick={() => {
                  onClose();
               }}
            >
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
