import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, Theme } from "@mui/material";
import { STRONG_BOX_SHADOW, XLARGE_PADDING_IN_PX } from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import { SxProps } from "@mui/system";
import DeckViewerCardColumn from "./DeckViewerCardColumn";
import { CardNameAndImage } from "../../cardFeatures/CardTypes";
import DeckViewerCardRow from "./DeckViewerCardRow";
import { SnapshotAllCards, useDeckListsApi } from "../../rest.client/useDeckListApi";
import { Maybe } from "../../TYPE";
import { groupCardsByMana, groupCardsByType, sortCardsByName } from "../../utils";
import _ from "lodash";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

const drawerContentContainerCss: CSSProperties = {
   display: "flex",
   flexDirection: "column",
   width: "100vw",
   height: "100vh",
};

const drawerContentHeaderWrapperCss: CSSProperties = {
   display: "flex",
   justifyContent: "space-evenly",
   alignContent: "center",
   columnGap: `${XLARGE_PADDING_IN_PX}px`,
   flexGrow: 0,
   padding: `${XLARGE_PADDING_IN_PX}px`,
   boxShadow: STRONG_BOX_SHADOW,
   zIndex: 1,
};

const drawerContentContentWrapperSx: SxProps<Theme> = {
   display: "flex",
   alignItems: "flex-start",
   flexWrap: "wrap",
   flexGrow: 1,
   overflow: "scroll",
   overflowX: "hidden",
   justifyContent: "space-evenly",
   background: (theme) => theme.palette.deckViewerBackgroundColor,
};

interface IDeckViewer {
   open: boolean;
   closeHandler: () => void;
   initialSnapId?: string;
   readonly?: boolean;
}

const generateCardColumnsAndSortByMana = (cards: Array<CardNameAndImage>) => {
   const cardsGroupedByMana = groupCardsByMana(cards);
   return Object.values(cardsGroupedByMana).map((cards, index) => <DeckViewerCardColumn key={index} cards={cards} />);
};

const generateLandCardColumns = (cards: Array<CardNameAndImage>) => {
   return _.chunk(sortCardsByName(cards), 10).map((cards, index) => <DeckViewerCardColumn key={index} cards={cards} />);
};

const DeckViewer: FC<IDeckViewer> = ({ open, closeHandler, initialSnapId, readonly }) => {
   const {
      getSnapshotAllData: { call: getSnapAllData },
   } = useDeckListsApi();
   const showMessage = useAddMessageItem();

   const [deckListAllData, setDeckListAllData] = useState<Maybe<SnapshotAllCards>>(undefined);

   const [commanderCards, setCommanderCards] = useState<Array<CardNameAndImage>>([]);
   const [creatureCards, setCreatureCards] = useState<Array<CardNameAndImage>>([]);
   const [sorceryCards, setSorceryCards] = useState<Array<CardNameAndImage>>([]);
   const [instantCards, setInstantCards] = useState<Array<CardNameAndImage>>([]);
   const [artifactCards, setArtifactCards] = useState<Array<CardNameAndImage>>([]);
   const [enchantmentCards, setEnchantmentCards] = useState<Array<CardNameAndImage>>([]);
   const [landCards, setLandCards] = useState<Array<CardNameAndImage>>([]);
   const [planeswalkerCards, setPlaneswalkerCards] = useState<Array<CardNameAndImage>>([]);

   useEffect(() => {
      if (initialSnapId) {
         getSnapAllData({ pathParams: { id: initialSnapId } }).then((snap) => {
            setDeckListAllData(snap);
         });
      }
   }, [getSnapAllData, initialSnapId]);

   useEffect(() => {
      if (deckListAllData) {
         const commanderCards: Array<CardNameAndImage> = [];
         deckListAllData.commander1 && commanderCards.push(deckListAllData.commander1);
         deckListAllData.commander2 && commanderCards.push(deckListAllData.commander2);
         setCommanderCards(commanderCards);

         const allCards = deckListAllData.mainboardCards.flatMap((mainboardCard) => {
            return [...Array(mainboardCard.quantity)].map(() => mainboardCard.card);
         });
         const cardsGroupedByType = groupCardsByType(allCards);
         setCreatureCards(cardsGroupedByType.creatures);
         setSorceryCards(cardsGroupedByType.sorceries);
         setInstantCards(cardsGroupedByType.instants);
         setArtifactCards(cardsGroupedByType.artifacts);
         setEnchantmentCards(cardsGroupedByType.enchantments);
         setPlaneswalkerCards(cardsGroupedByType.planeswalkers);
         setLandCards(cardsGroupedByType.lands);
      }
   }, [deckListAllData]);

   const commanderCardColumn = useMemo(() => {
      return commanderCards.map((card, index) => <DeckViewerCardColumn key={index} cards={[card]} />);
   }, [commanderCards]);

   return (
      <Dialog id={"deckViewer"} open={open} fullScreen={true}>
         <div style={drawerContentContainerCss}>
            <div style={drawerContentHeaderWrapperCss}>
               <Button variant={"contained"} onClick={closeHandler}>
                  Close
               </Button>
               <Button
                  color={"secondary"}
                  variant={"contained"}
                  onClick={() => {
                     navigator.clipboard.writeText(deckListAllData?.textList || "").then(() => {
                        showMessage("Decklist copied!", "success");
                     });
                  }}
               >
                  Copy as text
               </Button>
            </div>
            <Box sx={drawerContentContentWrapperSx}>
               <DeckViewerCardRow
                  rowTitle={`Commanders(${commanderCards.length})`}
                  cardColumns={[]}
                  commanderColumns={commanderCardColumn}
               />
               <DeckViewerCardRow
                  rowTitle={`Planeswalkers(${planeswalkerCards.length})`}
                  cardColumns={generateCardColumnsAndSortByMana(planeswalkerCards)}
               />
               <DeckViewerCardRow
                  rowTitle={`Creatures(${creatureCards.length})`}
                  cardColumns={generateCardColumnsAndSortByMana(creatureCards)}
               />
               <DeckViewerCardRow
                  rowTitle={`Sorceries(${sorceryCards.length})`}
                  cardColumns={generateCardColumnsAndSortByMana(sorceryCards)}
               />
               <DeckViewerCardRow
                  rowTitle={`Instants(${instantCards.length})`}
                  cardColumns={generateCardColumnsAndSortByMana(instantCards)}
               />
               <DeckViewerCardRow
                  rowTitle={`Artifacts(${artifactCards.length})`}
                  cardColumns={generateCardColumnsAndSortByMana(artifactCards)}
               />
               <DeckViewerCardRow
                  rowTitle={`Enchantments(${enchantmentCards.length})`}
                  cardColumns={generateCardColumnsAndSortByMana(enchantmentCards)}
               />
               <DeckViewerCardRow
                  rowTitle={`Lands(${landCards.length})`}
                  cardColumns={generateLandCardColumns(landCards)}
               />
            </Box>
         </div>
      </Dialog>
   );
};

export default DeckViewer;
