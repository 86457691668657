import React from "react";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RemoteStage } from "./useRemoteStage";
import { RoundFullResponse } from "../../../rest.client/useStagesApi";
import useRemoteRound from "./useRemoteRound";
import useAddMessageItem from "../../../messagesComponent/useAddMessageItem";
import BackDropSpinner from "../../../common/BackDropSpinner";

export default function MatchControls({
   remoteStage,
   selectedRound,
}: {
   remoteStage: RemoteStage;
   selectedRound: RoundFullResponse;
}) {
   const remoteRound = useRemoteRound(remoteStage, selectedRound);
   const theme = useTheme();
   const addMessage = useAddMessageItem();
   const nav = useNavigate();

   return (
      <Box>
         <BackDropSpinner show={remoteRound.loading} />
         <Box sx={{ display: "flex", gap: theme.spacing(1), flexWrap: "wrap" }}>
            <Tooltip
               title={
                  "Generate matches(pods) based on the configured paring strategy. You can only generate matches once hte round is active."
               }
               placement="top"
            >
               <Box>
                  <Button
                     variant={remoteRound.round.matches.length === 0 ? "contained" : "text"}
                     onClick={async () => {
                        await remoteRound.autoPair();
                        addMessage(
                           "Generation complete. Please verify all generated matches and byes to ensure suitability.",
                           "success"
                        );
                     }}
                     disabled={!remoteRound.canPair || remoteStage.isClosed}
                  >
                     Auto generate pods
                  </Button>
               </Box>
            </Tooltip>
            <Button
               disabled={!remoteRound.isActive}
               onClick={async () => {
                  await remoteRound.createMatch();
               }}
            >
               Add match
            </Button>
         </Box>
      </Box>
   );
}
