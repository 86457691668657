import React, { useEffect, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Button,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../utils";
import { RemoteTournament } from "../useRemoteTournament";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import PlayerStatsCard from "./PlayerStatsCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayerTournamentDetailsCard from "./PlayerTournamentDetailsCard";
import { StageFullResponse, useStageApi } from "../../rest.client/useStagesApi";
import RegistrationStatusBanner from "./RegistrationStatusBanner";
import DecklistInfoCard from "./DecklistInfoCard";
import RegistrationStageDetails from "./RegistrationStageDetails";
import useCurrentUser from "../../auth/useCurrentUser";
import TournamentRegistrationDialog from "./TournamentRegistrationDialog";
import useRemoteRegistration from "./useRemoteRegistration";
import RegistrationMenu from "./RegistrationMenu";
import { Maybe } from "../../TYPE";
import useReload from "../../header/useReload";
import RegistrationTagsExpander from "./RegistrationTagsExpander";
import InfoIcon from "@mui/icons-material/Info";
import StyleIcon from "@mui/icons-material/Style";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import FeedIcon from "@mui/icons-material/Feed";

export default function PlayerTournamentCardDialog({
   open,
   close,
   remoteTournament,
   registration,
}: {
   open: boolean;
   close: Procedure;
   remoteTournament: RemoteTournament;
   registration: RegistrationSummary;
}) {
   const theme = useTheme();
   const user = useCurrentUser();
   const isMobileDevice = !useMediaQuery("(min-width:600px)");
   const { reload } = useReload("registration");
   const {
      getStageFull: { call: getStageFull },
      loading,
   } = useStageApi();
   const remoteReg = useRemoteRegistration(remoteTournament, registration);
   const regFull = remoteReg.registrationFull;
   const loadData = remoteReg.loadData;
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const [globalStatsExpanded, setGlobalStatsExpanded] = useState(false);
   const [tournamentStatsExpanded, setTournamentStatsExpanded] = useState(true);
   const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);
   const [decklistExpanded, setDecklistExpanded] = useState(false);
   const [allStages, setAllStages] = useState<StageFullResponse[]>([]);
   const [stageExpanded, setStageExpanded] = useState<Record<string, boolean>>({});
   const [openEditRegistration, setOpenRegistration] = useState(false);
   const [selectedRegForCardDialog, setSelectedRegForCardDialog] = useState<Maybe<RegistrationSummary>>();

   useEffect(() => {
      if (open || reload) {
         loadData();
      }
   }, [loadData, open, reload]);

   //this is really not ideal we need a call to get all matches for a user and tournament
   useEffect(() => {
      const fetchData = async () => {
         if (open && remoteTournament.tournament) {
            const responses = await Promise.all(
               remoteTournament.tournament.stages.map((s) => getStageFull({ pathParams: { id: s.id } }))
            );
            setAllStages(responses);
         }
      };

      fetchData();
   }, [getStageFull, open, remoteTournament.tournament]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         maxWidth={"lg"}
         fullWidth={true}
         fullScreen={smallScreen}
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         {regFull && (
            <DialogTitle id="alert-dialog-title">
               <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>{regFull.user.name + "/" + regFull.user.shortTag + " - Tournament Card"}</Typography>
                  <RegistrationMenu
                     showViewOption={false}
                     registration={regFull}
                     remoteTournament={remoteTournament}
                     onClickViewReg={(reg) => setSelectedRegForCardDialog(reg)}
                     onClickEditReg={() => setOpenRegistration(true)}
                  />
                  <TournamentRegistrationDialog
                     remoteTournament={remoteTournament}
                     open={openEditRegistration}
                     tournamentId={""}
                     closeHandler={() => setOpenRegistration(false)}
                  />
                  {selectedRegForCardDialog && (
                     <PlayerTournamentCardDialog
                        open={true}
                        close={() => setSelectedRegForCardDialog(null)}
                        remoteTournament={remoteTournament}
                        registration={selectedRegForCardDialog}
                     />
                  )}
               </Box>
            </DialogTitle>
         )}
         <DialogContent sx={{ minWidth: isMobileDevice ? 0 : 600 }}>
            <Box
               style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: theme.spacing(1),
               }}
            >
               {loading && (
                  <Box sx={{}}>
                     <CircularProgress />
                  </Box>
               )}
            </Box>
            {regFull && <RegistrationStatusBanner registration={regFull} />}
            <Accordion
               expanded={tournamentStatsExpanded}
               onChange={() => setTournamentStatsExpanded(!tournamentStatsExpanded)}
            >
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                     }}
                  >
                     <InfoIcon />
                     <Typography>Player details</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  {open && regFull && (
                     <PlayerTournamentDetailsCard
                        allStages={allStages}
                        remoteTournament={remoteTournament}
                        registration={regFull}
                     />
                  )}
               </AccordionDetails>
            </Accordion>
            {regFull && <RegistrationTagsExpander remoteTournament={remoteTournament} fullRegistration={regFull} />}
            {remoteTournament.isOrganiser && (
               <Accordion expanded={additionalInfoOpen} onChange={() => setAdditionalInfoOpen(!additionalInfoOpen)}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           gap: theme.spacing(1),
                        }}
                     >
                        <FeedIcon />
                        <Typography>Additional organiser info</Typography>
                     </Box>
                  </AccordionSummary>
                  {remoteReg.registrationFull?.additionalDetails == null ||
                     (remoteReg.registrationFull?.additionalDetails === "" && (
                        <Alert severity="info">No additional information specified.</Alert>
                     ))}
                  <Typography sx={{ margin: theme.spacing(2) }}>
                     {remoteReg.registrationFull?.additionalDetails}
                  </Typography>
               </Accordion>
            )}
            <Accordion expanded={decklistExpanded} onChange={() => setDecklistExpanded(!decklistExpanded)}>
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                     }}
                  >
                     <StyleIcon />
                     <Typography>Player decklist information</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  {open && remoteReg.registrationFull?.snapshot && (
                     <DecklistInfoCard snapshot={remoteReg.registrationFull?.snapshot} registration={regFull} />
                  )}
                  {!remoteReg.registrationFull?.snapshot && !remoteTournament.isOrganiser && (
                     <Alert severity="warning">
                        No snapshot data available. Either the user did not provide snapshot data or it is not visible
                        to your account.
                     </Alert>
                  )}
                  {!remoteReg.registrationFull?.snapshot && remoteTournament.isOrganiser && (
                     <Alert severity="error">User has not provided a decklist during registration!</Alert>
                  )}
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={globalStatsExpanded} onChange={() => setGlobalStatsExpanded(!globalStatsExpanded)}>
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                     }}
                  >
                     <QueryStatsIcon />
                     <Typography>Global stats</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>{open && <PlayerStatsCard id={registration.user.id} />}</AccordionDetails>
            </Accordion>
            {allStages.map((s) => (
               <Accordion
                  expanded={stageExpanded[s.id]}
                  onChange={() => {
                     const updated = { ...stageExpanded };
                     updated[s.id] = !stageExpanded[s.id];
                     setStageExpanded(updated);
                  }}
               >
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           gap: theme.spacing(1),
                        }}
                     >
                        <FlagCircleIcon />
                        <Typography>Stage user data - {s.name}</Typography>
                     </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                     {regFull && (
                        <RegistrationStageDetails
                           remoteTournament={remoteTournament}
                           registration={regFull}
                           stage={s}
                        />
                     )}
                  </AccordionDetails>
               </Accordion>
            ))}
         </DialogContent>

         <DialogActions>
            <Button onClick={close} autoFocus>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
