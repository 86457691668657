import React, { useCallback, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   TextField,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../utils";
import { Maybe } from "../../TYPE";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TemplateTileButton from "./TemplateTileButton";
import useTemplateCreator from "./useTemplateCreator";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { toTournamentDetails } from "../../navPaths";
import StoreIcon from "@mui/icons-material/Store";
import PeopleIcon from "@mui/icons-material/People";
import { RegistrationMode } from "../../rest.client/useTournamentApi";

export type SwissCutDataType = Maybe<{
   variant: string;
   playerCount: number;
   cut: number;
}>;

export type SwissOnlyDataType = Maybe<{
   playerCount: number;
}>;

export type LeagueDataType = Maybe<{
   registrationMode: RegistrationMode;
   password?: string;
   variant: string;
}>;

export default function TournamentTemplateFlowDialog({ open, onClose }: { open: boolean; onClose: Procedure }) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const navigate = useNavigate();
   const addMessageItem = useAddMessageItem();
   const templateCreator = useTemplateCreator();
   const [name, setName] = useState<string>("");
   const [location, setLocation] = useState<string>("");
   const [openSection, setOpenSection] = useState("SWISS_CUT");
   const [swissCutData, setSwissCutData] = useState<SwissCutDataType>();
   const [swissOnlyData, setSwissOnlyData] = useState<SwissOnlyDataType>();
   const [leagueData, setLeagueData] = useState<LeagueDataType>();
   const [startDate, setStartDate] = useState<Maybe<Dayjs>>(dayjs(new Date()));
   const [password, setPassword] = useState<string>("");
   const valid =
      startDate != null &&
      name !== "" &&
      (swissCutData != null || leagueData != null || swissOnlyData != null) &&
      (leagueData?.registrationMode !== "PASSWORD" || password !== "");

   const setSwissCut = useCallback((data: SwissCutDataType) => {
      setLeagueData(null);
      setSwissCutData(data);
      setSwissOnlyData(null);
   }, []);

   const setLeague = useCallback((data: LeagueDataType) => {
      setLeagueData(data);
      setSwissCutData(null);
      setSwissOnlyData(null);
   }, []);

   const setSwissOnly = useCallback((data: SwissOnlyDataType) => {
      setLeagueData(null);
      setSwissCutData(null);
      setSwissOnlyData(data);
   }, []);

   const createTournament = useCallback(async () => {
      let result = null;
      if (swissCutData && valid) {
         result = await templateCreator.createSwissCut(
            name,
            location,
            startDate.toDate(),
            swissCutData.playerCount,
            swissCutData.cut
         );
      } else if (leagueData && valid) {
         result = await templateCreator.createLeague(
            name,
            location,
            startDate?.toDate(),
            leagueData.registrationMode,
            leagueData.password
         );
      } else if (swissOnlyData && valid) {
         result = await templateCreator.createSwissOnly(name, location, startDate?.toDate(), swissOnlyData.playerCount);
      }
      addMessageItem("Tournament created from template!", "success");
      result && navigate(toTournamentDetails(result.id));
   }, [
      addMessageItem,
      leagueData,
      location,
      name,
      navigate,
      startDate,
      swissCutData,
      swissOnlyData,
      templateCreator,
      valid,
   ]);

   return (
      <Dialog
         open={open}
         fullScreen={smallScreen}
         maxWidth={"lg"}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Create from template</DialogTitle>
         <DialogContent>
            <Box sx={{ marginTop: theme.spacing(1), display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
               <TextField
                  fullWidth
                  required
                  size={"small"}
                  variant={"outlined"}
                  label={"Tournament name"}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  inputProps={{ maxLength: 100 }}
               />
               <TextField
                  fullWidth
                  size={"small"}
                  variant={"outlined"}
                  label={"Location"}
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                  inputProps={{ maxLength: 2000 }}
               />
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                     sx={{ width: "100%" }}
                     format={"DD/MM/YYYY"}
                     label={"Start date"}
                     value={startDate}
                     onChange={(newDate: Dayjs | null) => {
                        setStartDate(newDate != null ? newDate : null);
                     }}
                     views={undefined}
                  />
               </LocalizationProvider>
               {leagueData?.registrationMode === "PASSWORD" && (
                  <TextField
                     fullWidth
                     required
                     type={"password"}
                     size={"small"}
                     variant={"outlined"}
                     label={"Password"}
                     onChange={(e) => setPassword(e.target.value)}
                     value={password}
                     inputProps={{ maxLength: 100 }}
                  />
               )}
            </Box>
            <Accordion expanded={openSection === "SWISS_CUT"} onChange={() => setOpenSection("SWISS_CUT")}>
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                     <Typography>Swiss-cut</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        flexWrap: "wrap",
                        justifyContent: "center",
                     }}
                  >
                     <TemplateTileButton
                        text={"Swiss-cut top 64"}
                        icon={64}
                        selected={swissCutData?.variant === "SWISS_CUT_64"}
                        onClick={() =>
                           setSwissCut({
                              playerCount: 128,
                              cut: 64,
                              variant: "SWISS_CUT_64",
                           })
                        }
                     />
                     <TemplateTileButton
                        text={"Swiss-cut top 40"}
                        icon={40}
                        selected={swissCutData?.variant === "SWISS_CUT_40"}
                        onClick={() =>
                           setSwissCut({
                              playerCount: 128,
                              cut: 40,
                              variant: "SWISS_CUT_40",
                           })
                        }
                     />
                     <TemplateTileButton
                        text={"Swiss-cut top 16"}
                        icon={16}
                        selected={swissCutData?.variant === "SWISS_CUT_16"}
                        onClick={() =>
                           setSwissCut({
                              playerCount: 64,
                              cut: 16,
                              variant: "SWISS_CUT_16",
                           })
                        }
                     />
                     <TemplateTileButton
                        text={"Swiss-cut top 10"}
                        selected={swissCutData?.variant === "SWISS_CUT_10"}
                        icon={10}
                        onClick={() =>
                           setSwissCut({
                              playerCount: 32,
                              cut: 10,
                              variant: "SWISS_CUT_10",
                           })
                        }
                     />
                     <TemplateTileButton
                        text={"Swiss-cut top 4"}
                        selected={swissCutData?.variant === "SWISS_CUT_4"}
                        icon={4}
                        onClick={() =>
                           setSwissCut({
                              playerCount: 32,
                              cut: 4,
                              variant: "SWISS_CUT_4",
                           })
                        }
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={openSection === "LEAGUE"} onChange={() => setOpenSection("LEAGUE")}>
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                     <Typography>League</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        flexWrap: "wrap",
                        justifyContent: "center",
                     }}
                  >
                     <TemplateTileButton
                        text={"Store league"}
                        icon={<StoreIcon sx={{ fontSize: "72px" }} />}
                        onClick={() => {
                           setLeague({ registrationMode: "PASSWORD", password, variant: "STORE_LEAGUE" });
                        }}
                        selected={leagueData?.variant === "STORE_LEAGUE"}
                     />
                     <TemplateTileButton
                        text={"Private league"}
                        icon={<PeopleIcon sx={{ fontSize: "72px" }} />}
                        onClick={() => {
                           setLeague({
                              registrationMode: "INVITE",
                              password: undefined,
                              variant: "PRIVATE_LEAGUE",
                           });
                        }}
                        selected={leagueData?.variant === "PRIVATE_LEAGUE"}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={openSection === "SWISS_ONLY"} onChange={() => setOpenSection("SWISS_ONLY")}>
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                     <Typography>Swiss only</Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        flexWrap: "wrap",
                        justifyContent: "center",
                     }}
                  >
                     <TemplateTileButton
                        text={"Swiss max 64 player"}
                        icon={64}
                        selected={swissOnlyData != null}
                        onClick={() => setSwissOnly({ playerCount: 64 })}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>
         </DialogContent>
         <DialogActions>
            <Button onClick={createTournament} disabled={!valid} variant={"contained"}>
               Create
            </Button>
            <Button onClick={onClose} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
