import React, { useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   List,
   ListItem,
   ListItemText,
   Typography,
   useTheme,
} from "@mui/material";
import { RemoteTournament } from "../../useRemoteTournament";
import { RemoteStage } from "./useRemoteStage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StageStatusBanner from "./StageStatusBanner";
import PointsListItems from "./PointsListItems";
import PersonIcon from "@mui/icons-material/Person";
import {
   getUserFriendlyNameForAutoPair,
   getUserFriendlyNameForByeAllocator,
   getUserFriendlyNameForPodOrder,
} from "../../../rest.client/useStagesApi";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";

export default function StageDetailsExpander({
   remoteTournament,
   remoteStage,
}: {
   remoteTournament: RemoteTournament;
   remoteStage: RemoteStage;
}) {
   const [expanded, setExpanded] = useState(false);
   const fullStage = remoteStage.stage;
   const theme = useTheme();
   const type =
      fullStage?.stageType === "SINGLE_ELIMINATION"
         ? "Elimination"
         : fullStage?.stageType === "UNSTRUCTURED_LEAGUE"
         ? "League"
         : "Swiss";
   const pointsProvider = remoteStage.stage?.unstructuredLeagueStage || remoteStage.stage?.swissLeagueStage;
   const itemStyle = { width: "120px" };
   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
               }}
            >
               <InfoIcon />
               <Typography>Details</Typography>
               {fullStage && <StageStatusBanner stage={fullStage} />}
            </Box>
         </AccordionSummary>
         {fullStage && (
            <AccordionDetails>
               <Box>
                  <List
                     dense={true}
                     sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        gap: theme.spacing(2),
                        alignItems: "start",
                     }}
                  >
                     <ListItem sx={itemStyle}>
                        <ListItemText
                           primary={
                              <Typography
                                 width={"130px"}
                                 overflow={"hidden"}
                                 textOverflow={"ellipsis"}
                                 whiteSpace={"nowrap"}
                                 fontWeight={"bold"}
                              >
                                 {fullStage.name}
                              </Typography>
                           }
                           secondary={"Name"}
                        />
                     </ListItem>
                     <ListItem sx={itemStyle}>
                        <ListItemText primary={type} secondary={"Type"} />
                     </ListItem>
                     {fullStage.stageType !== "UNSTRUCTURED_LEAGUE" && (
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={getUserFriendlyNameForAutoPair(fullStage.autoPairStrategy)}
                              secondary={
                                 <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <SettingsIcon fontSize={"small"} />
                                    Auto pair
                                 </Box>
                              }
                           />
                        </ListItem>
                     )}
                     {fullStage.stageType !== "UNSTRUCTURED_LEAGUE" && (
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={getUserFriendlyNameForPodOrder(fullStage.podOrderingStrategy)}
                              secondary={
                                 <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <SettingsIcon fontSize={"small"} />
                                    Player order
                                 </Box>
                              }
                           />
                        </ListItem>
                     )}
                     {fullStage.stageType !== "UNSTRUCTURED_LEAGUE" && (
                        <ListItem sx={itemStyle}>
                           <ListItemText
                              primary={
                                 <Typography
                                    variant={"body2"}
                                    width={"130px"}
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                 >
                                    {getUserFriendlyNameForByeAllocator(fullStage.byeAllocationStrategy)}
                                 </Typography>
                              }
                              secondary={
                                 <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <SettingsIcon fontSize={"small"} />
                                    Bye allocation
                                 </Box>
                              }
                           />
                        </ListItem>
                     )}
                     {pointsProvider && (
                        <PointsListItems
                           pointsProvider={pointsProvider}
                           byePoints={fullStage.swissLeagueStage?.byePoints}
                        />
                     )}
                     <ListItem sx={itemStyle}>
                        <ListItemText
                           primary={
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                 <PersonIcon fontSize={"small"} />
                                 {fullStage.playerCountLimit}
                              </Box>
                           }
                           secondary={"Max player count"}
                        />
                     </ListItem>
                     <ListItem sx={itemStyle}>
                        <ListItemText
                           primary={
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                 {fullStage.isAutoQualifyEnabled ? "Enabled" : "Disabled"}
                              </Box>
                           }
                           secondary={"Auto qualify"}
                        />
                     </ListItem>
                  </List>
               </Box>
            </AccordionDetails>
         )}
      </Accordion>
   );
}
