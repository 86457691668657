import React from "react";
import { Avatar, Box, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { LARGE_PADDING_IN_PX } from "../../../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import { StandingsRegResponse } from "../../../../rest.client/useStagesApi";

const topHorizontalClipValue = 40;
const bottomHorizontalClipValue = 100 - topHorizontalClipValue;
const playerBoxClipPath = `polygon(${topHorizontalClipValue}% 0%, 100% 0%, ${bottomHorizontalClipValue}% 100%, 0% 100%)`;

const playerBoxImageSx: SxProps = {
   boxSizing: "border-box",
   width: `calc(100% - ${LARGE_PADDING_IN_PX}px)`,
   height: `calc(100% - ${LARGE_PADDING_IN_PX}px)`,
   objectFit: "cover",
   margin: "auto",
   clipPath: playerBoxClipPath,
};

const playerBoxContentContainerSx: SxProps = {
   position: "absolute",
   display: "flex",
   flexDirection: "column",
   boxSizing: "border-box",
   width: "100%",
   height: "100%",
   top: "0px",
   left: "0px",
   backdropFilter: "brightness(0.4) blur(1px)",
   transition: "backdrop-filter 0.5s",
   "&:hover": {
      backdropFilter: "brightness(0.3) blur(1px)",
      "& .rankNumberText": {
         textShadow: "0 0 5px rgba(0,0,0,1), 0 0 10px rgba(0,0,0,1), 0 0 15px rgba(0,0,0,1)",
         color: "rgb(255,209,156)",
      },
      "& .playerBoxAvatar": {
         boxShadow: "0 0 2px rgba(0,0,0,1), 0 0 5px rgba(255,255,255,1), 0 0 10px rgb(229,156,0)",
      },
      "& .playerBoxName": {
         textShadow: "0 0 5px rgba(0,0,0,1), 0 0 10px rgba(0,0,0,1), 0 0 15px rgba(0,0,0,1)",
         color: "rgb(255,209,156)",
      },
      "& .playerBoxScore": {
         textShadow: "0 0 5px rgba(0,0,0,1), 0 0 10px rgba(0,0,0,1), 0 0 15px rgba(0,0,0,1)",
         color: "rgb(255,209,156)",
      },
   },
};

const playerBoxTextSx: SxProps<Theme> = {
   position: "absolute",
   textAlign: "center",
   textShadow: "0 0 1px rgba(255,255,255,0.8), 0 0 5px rgba(255,255,255,0.6), 0 0 10px rgba(229,156,0.4)",
   color: (theme) => theme.palette.primary.contrastText,
   fontStyle: "italic",
   pointerEvents: "none",
   left: "50%",
   top: "50%",
   transition: "text-shadow 0.5s, color 0.5s",
};

const rankNumberWrapperSx: SxProps = {
   position: "relative",
   display: "flex",
   width: "100%",
   height: "40%",
};

const rankNumberSx: SxProps<Theme> = {
   ...playerBoxTextSx,
   fontSize: "50px",
   transform: "translate(40%, -50%)",
};

const playerBoxAvatarWrapperSx: SxProps = {
   position: "relative",
   display: "flex",
   width: "100%",
};

const playerBoxAvatarSx: SxProps = {
   position: "relative",
   width: `50px`,
   height: `50px`,
   margin: "auto",
   transition: "box-shadow 0.5s",
};

const playerBoxNameWrapperSx: SxProps = {
   position: "relative",
   display: "flex",
   width: "100%",
   height: "15%",
};

const playerBoxNameSx: SxProps<Theme> = {
   ...playerBoxTextSx,
   fontSize: "20px",
   transform: "translate(-58%, -50%)",
};

const playerBoxScoreWrapperSx: SxProps = {
   position: "relative",
   display: "flex",
   height: "10%",
};

const playerBoxScoreSx: SxProps<Theme> = {
   ...playerBoxTextSx,
   fontSize: "30px",
   transform: "translate(-85%, -20%)",
};

export default function TopPlayerCard({
   index,
   standing,
   rootSx,
}: {
   index: number;
   standing: StandingsRegResponse;
   rootSx: SxProps;
}) {
   if (!standing) {
      return null;
   }

   const imgSrc = standing.registration.commander1.normalImageUri;

   return (
      <Box id={`playerBox${index}`} sx={rootSx}>
         <Box sx={playerBoxImageSx} component={"img"} src={imgSrc} />
         <Box sx={playerBoxContentContainerSx}>
            <Box sx={rankNumberWrapperSx}>
               <Typography className={"rankNumberText"} sx={rankNumberSx}>
                  {index + 1}
               </Typography>
            </Box>
            <Box sx={playerBoxAvatarWrapperSx}>
               <Avatar className={"playerBoxAvatar"} sx={playerBoxAvatarSx} alt="Remy Sharp" />
            </Box>
            <Box sx={playerBoxNameWrapperSx}>
               <Typography className={"playerBoxName"} sx={playerBoxNameSx}>
                  {standing.registration.user.name} / {standing.registration.user.shortTag}
               </Typography>
            </Box>
            <Box sx={playerBoxScoreWrapperSx}>
               <Typography className={"playerBoxScore"} sx={playerBoxScoreSx}>
                  {standing.standing.pointsTotal} - {standing.standing.wins} - {standing.standing.losses} -{" "}
                  {standing.standing.draws}
               </Typography>
            </Box>
         </Box>
      </Box>
   );
}
