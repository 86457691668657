import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { SnapshotSummary, useDeckListsApi } from "../../rest.client/useDeckListApi";
import { Maybe } from "../../TYPE";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import Spinner from "../../common/Spinner";

export default function TextDecklistCreator({
   onSnapshotUpdate,
   snapshot,
   decklistLocked,
   required,
}: {
   onSnapshotUpdate: (deckListAllData: SnapshotSummary) => void;
   snapshot: Maybe<SnapshotSummary>;
   decklistLocked: boolean;
   required?: boolean;
}) {
   const theme = useTheme();
   const showMessage = useAddMessageItem();
   const [text, setText] = useState("");
   const [name, setName] = useState("Unnamed decklist");
   const {
      importDeckList: { call: importDeckList },
      getSnapshotAllData: { call: getAllCards, responseData: allCardResponse },
      loading,
   } = useDeckListsApi();
   const [snapShotSuccess, setSnapShotSuccess] = useState<Maybe<boolean>>(null);
   const [snapShotFail, setSnapShotFail] = useState<Maybe<boolean>>(null);

   useEffect(() => {
      if (snapshot) {
         getAllCards({ pathParams: { id: snapshot.id } }).then((resp) => {
            setText(resp.textList);
            setName(resp.name);
         });
      }
   }, [getAllCards, snapshot]);

   const save = useCallback(async () => {
      const deckSaveResult = await importDeckList({ body: { sourceType: "TEXT", textList: text, name: name } });
      showMessage("Decklist " + name + " saved successfully.", "success");
      setSnapShotSuccess(true);
      onSnapshotUpdate(deckSaveResult.latestSnapshot);
   }, [importDeckList, name, onSnapshotUpdate, showMessage, text]);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
         }}
      >
         <TextField
            fullWidth
            disabled={loading || decklistLocked}
            size={"small"}
            variant={"outlined"}
            label={"Name"}
            value={name}
            inputProps={{ maxLength: 60 }}
            onChange={(e) => setName(e.target.value)}
         />
         {!decklistLocked && (
            <Alert severity={"info"}>
               Paste or edit decklist below in the format "1 Brain Freeze" or "1x Brain Freeze (PLST) SCG-29" per line.
               Please note that printing information will not be persisted.
            </Alert>
         )}
         <TextField
            fullWidth
            multiline
            disabled={loading || decklistLocked}
            rows={5}
            size={"small"}
            variant={"outlined"}
            label={"Paste deck list here"}
            value={text}
            inputProps={{ maxLength: 10000 }}
            onChange={(e) => setText(e.target.value)}
         />
         {snapShotFail && (
            <Alert severity="error">
               <Typography>Failed to create decklist please check your text and try again.</Typography>
            </Alert>
         )}
         <Box>
            <Spinner show={loading} />
         </Box>
         <Button
            variant={"contained"}
            disabled={
               (text === allCardResponse?.textList && name === allCardResponse.name) ||
               text === "" ||
               loading ||
               decklistLocked
            }
            onClick={save}
         >
            Save decklist
         </Button>
      </Box>
   );
}
