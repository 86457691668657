import React, { useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Paper,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import { RemoteTournament } from "../useRemoteTournament";
import EditTournamentResultsExpander from "./prizeTiers/EditTournamentResultsExpander";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TournamentControls from "./TournamentControls";
import TournamentDetailsCard from "./TournamentDetailsCard";
import OverallStandingsTableExpander from "./OverallStandingsTableExpander";
import RegistrationListExpander from "../registration/RegistrationListExpander";
import MarkdownDescriptionDisplay from "../MarkdownDescriptionDisplay";
import TagsExpander from "./tags/TagsExpander";
import InfoIcon from "@mui/icons-material/Info";

export default function TournamentDetails({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const theme = useTheme();
   const tournament = remoteTournament.tournament;
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [expanded, setExpanded] = useState(true);

   return (
      <Box sx={{ width: "100%" }}>
         <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
               <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  <InfoIcon />
                  <Typography>Tournament details</Typography>
               </Box>
            </AccordionSummary>
            <AccordionDetails>
               <TournamentDetailsCard remoteTournament={remoteTournament} />

               <Paper
                  sx={{
                     display: "flex",
                     justifyContent: "space-around",
                     alignItems: "flex-start",
                     flexWrap: "wrap",
                  }}
               >
                  <TournamentControls remoteTournament={remoteTournament} />
                  {remoteTournament.tournament && (
                     <MarkdownDescriptionDisplay
                        description={remoteTournament.tournament.description}
                        sx={{
                           padding: theme.spacing(4),
                           // minWidth: "200px",
                           // flexGrow: 1,
                           maxHeight: "600px",
                           maxWidth: smallScreen ? "100%" : "75%",
                           minHeight: "250px",
                           overflow: "auto",
                        }}
                     />
                  )}
               </Paper>
            </AccordionDetails>
         </Accordion>
         {(remoteTournament.isOrganiser ||
            tournament?.isPublic ||
            ((tournament?.status === "ENDED" || tournament?.status === "STARTED") && tournament?.canAccessData)) && (
            <RegistrationListExpander remoteTournament={remoteTournament} />
         )}
         {remoteTournament.isOrganiser && (
            <EditTournamentResultsExpander open={remoteTournament.hasEnded} remoteTournament={remoteTournament} />
         )}
         {remoteTournament.isOrganiser && <TagsExpander remoteTournament={remoteTournament} />}
         {tournament?.canAccessData && <OverallStandingsTableExpander remoteTournament={remoteTournament} />}
      </Box>
   );
}
