import { GameSummary, PlayerSummary, useApplicationApi } from "../rest.client/useApplicationApi";
import { useCallback, useMemo } from "react";
import useCurrentUser from "../auth/useCurrentUser";
import { Supplier } from "../utils";
import { Maybe } from "../TYPE";

export interface RemoteGame {
   submitResult: (playerId: Maybe<string>) => Promise<GameSummary>;
   approveGame: () => Promise<GameSummary>;
   disputeGame: () => Promise<GameSummary>;
   isCurrentUserInGame: boolean;
   canSubmitResult: boolean;
   myPlayer: PlayerSummary | undefined;
   winner: PlayerSummary | undefined;
   deleteGame: Supplier<Promise<void>>;
   allPlayers: PlayerSummary[];
   isUserInGameOrCreator: boolean;
}

export default function useRemoteGame(gameSummary: GameSummary): RemoteGame {
   const {
      submitResult: { call: submitResultCall },
      approveGame: { call: approveGameCall },
      disputeGame: { call: disputeGameCall },
      deleteGame: { call: deleteGameCall },
   } = useApplicationApi();
   const currentUser = useCurrentUser();
   const allPlayers: PlayerSummary[] = [
      gameSummary.player1,
      gameSummary.player2,
      gameSummary.player3,
      gameSummary.player4,
   ].filter((p) => p != null) as PlayerSummary[];
   const isCurrentUserInGame = allPlayers.some((p) => p?.user.id === currentUser.user?.uid);
   const canSubmitResult =
      gameSummary.status !== "APPROVED" && (isCurrentUserInGame || currentUser.user?.uid === gameSummary.creator.id);
   const myPlayer = allPlayers.find((p) => p.user.id === currentUser.user?.uid);
   const winner = allPlayers.find((p) => p.winner);
   const isUserInGameOrCreator = myPlayer != null || gameSummary.creator.id === currentUser.user?.uid;

   const deleteGame = useCallback(() => {
      return deleteGameCall({ pathParams: { id: gameSummary.id } });
   }, [deleteGameCall, gameSummary.id]);

   const submitResult = useCallback(
      async (playerId: Maybe<string>) => {
         return await submitResultCall({ pathParams: { id: gameSummary.id }, body: { playerId: playerId } });
      },
      [gameSummary.id, submitResultCall]
   );

   const approveGame = useCallback(async () => {
      return await approveGameCall({ pathParams: { id: gameSummary.id } });
   }, [approveGameCall, gameSummary.id]);

   const disputeGame = useCallback(async () => {
      return await disputeGameCall({ pathParams: { id: gameSummary.id } });
   }, [disputeGameCall, gameSummary.id]);

   return useMemo(
      () => ({
         deleteGame,
         isUserInGameOrCreator,
         submitResult,
         allPlayers,
         disputeGame,
         approveGame,
         winner,
         isCurrentUserInGame,
         canSubmitResult,
         myPlayer,
      }),
      [
         deleteGame,
         isUserInGameOrCreator,
         submitResult,
         allPlayers,
         disputeGame,
         approveGame,
         winner,
         isCurrentUserInGame,
         canSubmitResult,
         myPlayer,
      ]
   );
}
