import React, { useEffect, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Button,
   Tooltip,
   Typography,
   useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { RegistrationSummary } from "../../../rest.client/useTournamentApi";
import { Maybe } from "../../../TYPE";
import { RemoteStage } from "./useRemoteStage";
import RegistrationSelector from "../matches/editDialog/RegistrationSelector";
import RegistrationChip from "../../registration/RegistrationChip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import HelpIcon from "../../../common/HelpIcon";

export default function QualifiedPlayersExpander({ remoteStage }: { remoteStage: RemoteStage }) {
   const theme = useTheme();
   const seeds = remoteStage.seeds || [];

   useEffect(() => {
      if (remoteStage.seeds == null) {
         remoteStage.loadSeeds();
      }
   }, [remoteStage]);
   const [selectedReg, setSelectedReg] = useState<Maybe<RegistrationSummary>>();

   return (
      <Accordion defaultExpanded={false} onChange={(ev, expanded) => expanded && remoteStage.loadSeeds()}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <HowToRegIcon />
               <Typography>Qualified players</Typography>
               <HelpIcon
                  message={
                     "This is the pool of players that are considered qualified for the stage. " +
                     "The auto-pairing system will use this pool to generate matches during the stage."
                  }
               />
               <Alert severity={seeds.length === 0 ? "warning" : "success"}>{seeds.length} players qualified!</Alert>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            {remoteStage.remoteTournament.tournament != null && (
               <>
                  <Box sx={{ margin: theme.spacing(1) }}>
                     {remoteStage.canEdit && (
                        <Box
                           sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: theme.spacing(2),
                              alignItems: "center",
                           }}
                        >
                           <Tooltip
                              title={
                                 "This will automatically populate the qualified players from either the tournament accepted registered player pool " +
                                 "or the previous stage standings. If there is a previous stage players are selected based on their standings in the previous stage. " +
                                 "Players are selected until the maximum player count for the stage is reached " +
                                 "or there are no remaining players to select"
                              }
                              placement="top"
                           >
                              <Box>
                                 <Button
                                    disabled={remoteStage.isClosed}
                                    sx={{ marginBottom: theme.spacing(1) }}
                                    onClick={() => remoteStage.autoSeed()}
                                    variant={"contained"}
                                 >
                                    Auto populate
                                 </Button>
                              </Box>
                           </Tooltip>
                           <Box sx={{ display: "flex" }}>
                              <RegistrationSelector
                                 readOnly={!remoteStage.remoteTournament.isOrganiser || remoteStage.isClosed}
                                 inUseRegs={seeds}
                                 label={"Manually select"}
                                 reg={selectedReg}
                                 setRegistration={setSelectedReg}
                                 registrations={remoteStage.remoteTournament.tournament.registrations}
                              />
                              {
                                 <Button
                                    onClick={async (e) => {
                                       remoteStage.remoteTournament.tournament &&
                                          selectedReg &&
                                          (await remoteStage.seedPlayer(selectedReg.id));
                                       setSelectedReg(null);
                                    }}
                                    disabled={selectedReg == null}
                                 >
                                    Add player
                                 </Button>
                              }
                           </Box>
                        </Box>
                     )}
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        justifyContent: "flex-start",
                     }}
                  >
                     {seeds
                        .sort((a, b) => a.user.name.localeCompare(b.user.name))
                        .map((b) => {
                           return (
                              <Box alignItems="flex-start" key={b.id}>
                                 <RegistrationChip registration={b}>
                                    {remoteStage.canEdit && (
                                       <Button
                                          onClick={async (e) => {
                                             await remoteStage.deleteSeed(b.id);
                                          }}
                                       >
                                          <DeleteForeverIcon />
                                       </Button>
                                    )}
                                 </RegistrationChip>
                              </Box>
                           );
                        })}
                  </Box>
                  {seeds.length === 0 && <Alert severity={"info"}>No players seeded for stage!</Alert>}
               </>
            )}
         </AccordionDetails>
      </Accordion>
   );
}
