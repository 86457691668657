import React from "react";
import { DEFAULT_SWISS_SETTINGS, RemoteStage } from "../stageView/useRemoteStage";
import AdditionalPointsOptions from "./AdditionalPointsOptions";
import DynamicNumberField from "./DynamicNumberField";
import TieBreakerSystemDropdown from "./TieBreakerSystemDropdown";
import { ByeAllocationStrategy, getUserFriendlyNameForByeAllocator } from "../../../rest.client/useStagesApi";
import { Box, MenuItem, TextField, useTheme } from "@mui/material";
import HelpIcon from "../../../common/HelpIcon";

export default function SwissOptions({
   remoteStage,
   setError,
}: {
   remoteStage: RemoteStage;
   setError: (key: string, isInError: boolean) => void;
}) {
   const theme = useTheme();
   const { saveRequest, setSaveRequest } = remoteStage;
   const swissSettings = saveRequest.swissSettings;

   return (
      <>
         <TextField
            sx={{ marginTop: theme.spacing(1) }}
            fullWidth
            required
            select
            variant={"outlined"}
            label={"Bye allocation"}
            value={saveRequest.swissSettings?.byeAllocationStrategy}
            onChange={(e) => {
               const selection = e.target.value as ByeAllocationStrategy;
               setSaveRequest({
                  ...saveRequest,
                  swissSettings:
                     swissSettings != null
                        ? {
                             ...swissSettings,
                             byeAllocationStrategy: selection,
                          }
                        : null,
               });
            }}
            inputProps={{ maxLength: 100 }}
         >
            <MenuItem key={""} value={"MAMTR"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForByeAllocator("MAMTR")}
                  <HelpIcon
                     message={
                        "This bye allocator will not allocate any byes and will pair a max of 3 pods of 3 players. " +
                        "It will attempt to avoid players being allocated to 3 player pods multiple times." +
                        " This is done on a best effort basis and correct pod pairing always takes precedence."
                     }
                  />
               </Box>
            </MenuItem>
            <MenuItem key={""} value={"MSTR_REGULAR_REL"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForByeAllocator("MSTR_REGULAR_REL")}
                  <HelpIcon
                     message={
                        "This bye allocator will allocate a max of 1 pod of 3 players and a max of 2 byes. " +
                        "Byes are allocated on a last remaining basis with an attempt to avoid players being allocated byes multiple times."
                     }
                  />
               </Box>
            </MenuItem>
            <MenuItem key={""} value={"MSTR_COMPETITIVE_REL"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForByeAllocator("MSTR_COMPETITIVE_REL")}
                  <HelpIcon
                     message={
                        "This bye allocator will allocate a max of 3 byes and will never pair 3 player games. " +
                        "Please note that this quantity of byes can have a very significant effect on final standings " +
                        "of Swiss stages, especially on low player count events use with caution."
                     }
                  />
               </Box>
            </MenuItem>
            <MenuItem key={""} value={"REDUCED_BYES"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForByeAllocator("REDUCED_BYES")}
                  <HelpIcon
                     message={
                        "Similar to MAMTR bye allocation this will not allocate any byes but will attempt to pair up to a max " +
                        "of 3 pods of 3 players. This allocator will not attempt to avoid players being allocated to " +
                        "multiple 3 player pods, all allocation is on a last remaining basis."
                     }
                  />
               </Box>
            </MenuItem>
         </TextField>
         <TieBreakerSystemDropdown
            tiebreakerSystem={saveRequest.swissSettings?.tiebreakerSystem || DEFAULT_SWISS_SETTINGS.tiebreakerSystem}
            onChange={(e) => {
               setSaveRequest({
                  ...saveRequest,
                  swissSettings:
                     saveRequest.swissSettings != null
                        ? {
                             ...saveRequest.swissSettings,
                             tiebreakerSystem: e,
                          }
                        : null,
               });
            }}
            setError={setError}
         />
         <DynamicNumberField
            label={"Points for win"}
            errorMapKey={"winPoints"}
            setError={setError}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                winPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.winPoints || 0}
         />
         <DynamicNumberField
            label={"Points for loss"}
            errorMapKey={"lossPoints"}
            setError={setError}
            isError={(num) => num > 0}
            onChange={(points) => {
               if (points <= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                lossPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.lossPoints}
         />
         <DynamicNumberField
            label={"Points for draw"}
            errorMapKey={"drawPoints"}
            setError={setError}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                drawPoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.drawPoints}
         />
         <DynamicNumberField
            label={"Points for bye"}
            errorMapKey={"byePoints"}
            setError={setError}
            onChange={(points) => {
               if (points >= 0) {
                  setSaveRequest({
                     ...saveRequest,
                     swissSettings:
                        saveRequest.swissSettings != null
                           ? {
                                ...saveRequest.swissSettings,
                                byePoints: points,
                             }
                           : null,
                  });
               }
            }}
            value={saveRequest.swissSettings?.byePoints}
         />
         <AdditionalPointsOptions
            setError={setError}
            additionalPoints={{
               secondPlacePoints: swissSettings?.secondPlacePoints || 0,
               thirdPlacePoints: swissSettings?.thirdPlacePoints || 0,
               fourthPlacePoints: swissSettings?.fourthPlacePoints || 0,
               matchWinPercentageFloor: swissSettings?.matchWinPercentageFloor || 0.2,
            }}
            onChangePoints={(additionalSettings) =>
               setSaveRequest({
                  ...saveRequest,
                  swissSettings:
                     saveRequest.swissSettings != null
                        ? {
                             ...saveRequest.swissSettings,
                             ...additionalSettings,
                          }
                        : null,
               })
            }
         />
      </>
   );
}
