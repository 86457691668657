import React from "react";
import { RemoteStage } from "../stageView/useRemoteStage";

export default function SingleEliminationOptions({
   remoteStage,
   setError,
}: {
   remoteStage: RemoteStage;
   setError: (key: string, isInError: boolean) => void;
}) {
   const { saveRequest, setSaveRequest } = remoteStage;

   const playerCount = saveRequest.playerCountLimit;

   return <></>;
}
