import React, { useCallback, useState } from "react";
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControlLabel,
   FormGroup,
   MenuItem,
   TextField,
   useTheme,
} from "@mui/material";
import { Procedure } from "../../../utils";
import useRemoteStage, {
   DEFAULT_LEAGUE_SETTINGS,
   DEFAULT_SINGLE_ELIMINATION_SETTINGS,
   DEFAULT_SWISS_SETTINGS,
} from "../stageView/useRemoteStage";
import { RemoteTournament } from "../../useRemoteTournament";
import SwissOptions from "./SwissOptions";
import LeagueOptions from "./LeagueOptions";
import SingleEliminationOptions from "./SingleEliminationOptions";
import {
   AutoPairStrategy,
   getUserFriendlyNameForAutoPair,
   getUserFriendlyNameForPodOrder,
   PodOrderStrategy,
} from "../../../rest.client/useStagesApi";
import MaxPlayerCountField from "./MaxPlayerCountField";
import Checkbox from "@mui/material/Checkbox";
import HelpIcon from "../../../common/HelpIcon";

const textFieldStyle = { marginTop: "10px" };

export default function CreateOrEditStageDialog({
   open,
   close,
   remoteTournament,
   id,
}: {
   open: boolean;
   close: Procedure;
   remoteTournament: RemoteTournament;
   id?: string;
}) {
   const remoteStage = useRemoteStage(remoteTournament, id);
   const theme = useTheme();
   const { save, saveRequest, setSaveRequest } = remoteStage;
   const [fieldErrorMap, setFieldErrorMap] = useState<Record<string, boolean>>({});
   const showAutoSettings =
      remoteStage.saveRequest.singleEliminationSettings != null || remoteStage.saveRequest.swissSettings != null;
   const showAutoQualify =
      (remoteStage.stage != null && remoteStage.stage?.previousStage == null) ||
      remoteTournament.tournament?.stages.length === 0;

   const setError = useCallback((key: string, error: boolean) => {
      setFieldErrorMap((map) => {
         const newMap = { ...map };
         newMap[key] = error;
         return newMap;
      });
   }, []);

   return (
      <Dialog
         open={open}
         maxWidth={"md"}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{id == null ? "Add new stage" : "Edit stage"}</DialogTitle>
         <DialogContent sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
            <TextField
               sx={textFieldStyle}
               fullWidth
               required
               variant={"outlined"}
               label={"Name"}
               onChange={(event) => setSaveRequest({ ...saveRequest, name: event.target.value })}
               value={saveRequest.name}
               inputProps={{ maxLength: 100 }}
            />
            <TextField
               sx={textFieldStyle}
               fullWidth
               required
               select
               disabled={id != null}
               variant={"outlined"}
               label={"Type"}
               value={
                  saveRequest.singleEliminationSettings != null
                     ? "SINGLE_ELIMINATION"
                     : saveRequest.unstructuredLeagueSettings != null
                     ? "LEAGUE"
                     : "SWISS"
               }
               onChange={(e) => {
                  const selection = e.target.value as string;
                  setFieldErrorMap({});
                  if (selection === "SWISS") {
                     setSaveRequest({
                        ...saveRequest,
                        autoPairStrategy: "RANDOM",
                        swissSettings: DEFAULT_SWISS_SETTINGS,
                        unstructuredLeagueSettings: null,
                        singleEliminationSettings: null,
                     });
                  } else if (selection === "LEAGUE") {
                     setSaveRequest({
                        ...saveRequest,
                        autoPairStrategy: "RANDOM",
                        swissSettings: null,
                        singleEliminationSettings: null,
                        unstructuredLeagueSettings: DEFAULT_LEAGUE_SETTINGS,
                     });
                  } else if (selection === "SINGLE_ELIMINATION") {
                     setSaveRequest({
                        ...saveRequest,
                        autoPairStrategy: "MAMTR_ELIMINATION_PAIRING",
                        singleEliminationSettings: DEFAULT_SINGLE_ELIMINATION_SETTINGS,
                        unstructuredLeagueSettings: null,
                        swissSettings: null,
                     });
                  }
               }}
               inputProps={{ maxLength: 100 }}
            >
               <MenuItem key={""} value={"LEAGUE"}>
                  League
               </MenuItem>
               <MenuItem key={""} value={"SWISS"}>
                  Swiss
               </MenuItem>
               <MenuItem key={""} value={"SINGLE_ELIMINATION"}>
                  Single elimination (Knockout)
               </MenuItem>
            </TextField>
            <MaxPlayerCountField remoteStage={remoteStage} setError={setError} />
            {showAutoQualify && (
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormGroup>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={saveRequest.autoQualifyEnabled}
                              onChange={(ev, checked) =>
                                 setSaveRequest({
                                    ...saveRequest,
                                    autoQualifyEnabled: checked,
                                 })
                              }
                           />
                        }
                        label="Auto qualify when registration accepted"
                     />
                  </FormGroup>
                  <HelpIcon
                     message={
                        "Should players be automatically qualified into this stage when their registration is accepted?"
                     }
                  />
               </Box>
            )}
            {showAutoSettings && (
               <TextField
                  sx={textFieldStyle}
                  fullWidth
                  required
                  select
                  variant={"outlined"}
                  label={"Pod pairing"}
                  value={saveRequest?.autoPairStrategy}
                  onChange={(e) => {
                     const selection = e.target.value as AutoPairStrategy;
                     setSaveRequest({
                        ...saveRequest,
                        autoPairStrategy: selection,
                     });
                  }}
                  inputProps={{ maxLength: 100 }}
               >
                  <MenuItem key={""} value={"RANDOM"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("RANDOM")}
                        <HelpIcon
                           message={
                              "A basic random pod pairing strategy. As with all strategies here repeated opponent " +
                              "pairings are avoided but no other metrics are used to decide allocations."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"ELO_BASED_NON_GROUPED"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("ELO_BASED_NON_GROUPED")}
                        <HelpIcon
                           message={
                              "Pair players based on their Elo rating. A players Elo is calculated based on the outcomes of previous games in the Brainstorm system."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"ELO_BASED_GROUPED"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("ELO_BASED_GROUPED")}
                        <HelpIcon
                           message={
                              "A group based Elo pairing varient. Players are divided into 4 groups based on their Elo rating " +
                              "and players are selected from each group to make pods of 4 players." +
                              " This system is probably the most common pairing system in Chess however it can be effective in" +
                              " cEDH also since it means pods have a very balanced skill distribution."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"MAMTR_SWISS_PAIRING"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("MAMTR_SWISS_PAIRING")}
                        <HelpIcon
                           message={
                              "This is the Swiss pairing algorithm described in the MAMTR rules documentation. " +
                              "It is standings based and details can be found on https://juizes-mtg-portugal.github.io/"
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_BASED_GROUPED"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("STANDINGS_BASED_GROUPED")}
                        <HelpIcon
                           message={
                              "This paring strategy is similar to MAMTR Swiss however it differs in that players will be divided into" +
                              " 4 groups based on their standings. A player from each group will be selected to form pods. This strategy cam " +
                              "provide advantages as it ensures each group has a reduced incentive to ID and a more balanced pod average skill level can be achieved."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_PREVIOUS_STAGE_NON_GROUPED"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("STANDINGS_PREVIOUS_STAGE_NON_GROUPED")}
                        <HelpIcon
                           message={
                              "This strategy pairs players based on the previous stage standings. Players who had similar" +
                              " results will be paired together." +
                              "this would be typically used for single elimination stages only."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"STANDINGS_PREVIOUS_STAGE_GROUPED"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("STANDINGS_PREVIOUS_STAGE_GROUPED")}
                        <HelpIcon
                           message={
                              "A group based variant that pairs based on previous stage standings, this can provide balanced pods for elimination stages."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"MAMTR_ELIMINATION_PAIRING"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForAutoPair("MAMTR_ELIMINATION_PAIRING")}
                        <HelpIcon
                           message={
                              "This is the single elimination pairing algorithm described in the MAMTR rules documentation. " +
                              "It is previous stage standings based and details can be found on https://juizes-mtg-portugal.github.io/"
                           }
                        />
                     </Box>
                  </MenuItem>
               </TextField>
            )}
            {showAutoSettings && (
               <TextField
                  sx={textFieldStyle}
                  fullWidth
                  required
                  select
                  variant={"outlined"}
                  label={"Player seat order"}
                  value={saveRequest.podOrderingStrategy}
                  onChange={(e) => {
                     const selection = e.target.value as PodOrderStrategy;
                     setSaveRequest({
                        ...saveRequest,
                        podOrderingStrategy: selection,
                     });
                  }}
                  inputProps={{ maxLength: 100 }}
               >
                  <MenuItem key={""} value={"RANDOM"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForPodOrder("RANDOM")}
                        <HelpIcon message={"Players will be allocated seats in each game randomly."} />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"PREVIOUS_STAGE_STANDINGS"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForPodOrder("PREVIOUS_STAGE_STANDINGS")}
                        <HelpIcon
                           message={
                              "Players will be allocated seats based on their performance in the previous stage. " +
                              "Players who had a higher standing in the Swiss for example will be given a better seat position."
                           }
                        />
                     </Box>
                  </MenuItem>
                  <MenuItem key={""} value={"OVERALL_TOURNAMENT_STANDINGS"}>
                     <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                        {getUserFriendlyNameForPodOrder("OVERALL_TOURNAMENT_STANDINGS")}
                        <HelpIcon
                           message={
                              "Players will be allocated seats based on their performance in the overall tournament. " +
                              "Players who have a higher standing in the tournament will be given a better seat position."
                           }
                        />
                     </Box>
                  </MenuItem>
               </TextField>
            )}
            {saveRequest.swissSettings && <SwissOptions remoteStage={remoteStage} setError={setError} />}
            {saveRequest.unstructuredLeagueSettings && <LeagueOptions remoteStage={remoteStage} setError={setError} />}
            {saveRequest.singleEliminationSettings && (
               <SingleEliminationOptions setError={setError} remoteStage={remoteStage} />
            )}
         </DialogContent>
         <DialogActions>
            <Button
               onClick={async () => {
                  await save();
                  close();
               }}
               disabled={Object.values(fieldErrorMap).some((v) => v)}
               color={"secondary"}
            >
               Save
            </Button>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
