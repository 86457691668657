import { Box, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useCallback } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Consumer } from "../../utils";
import { RulesInfractionSummary } from "../../rest.client/useRulesInfractionApi";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

export default function RulesInfractionMenu({
   rulesInfraction,
   onClickDelete,
   onClickEdit,
}: {
   rulesInfraction: RulesInfractionSummary;
   onClickDelete?: Consumer<RulesInfractionSummary>;
   onClickEdit: Consumer<RulesInfractionSummary>;
}) {
   const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

   const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
      event.stopPropagation();
   }, []);

   const handleCloseUserMenu = useCallback((event: React.MouseEvent, reason: string) => {
      setAnchorElUser(null);
      event.stopPropagation();
   }, []);

   return (
      <Box sx={{ cursor: "pointer" }}>
         <span onClick={handleOpenUserMenu}>{<MoreVertIcon />}</span>
         <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "right",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
         >
            <MenuItem
               onClick={async (e) => {
                  e.stopPropagation();
                  onClickEdit(rulesInfraction);
                  setAnchorElUser(null);
               }}
            >
               <ListItemIcon>
                  <EditIcon color={"info"} fontSize="small" />
               </ListItemIcon>
               <Typography>Edit</Typography>
            </MenuItem>
            {onClickDelete && (
               <MenuItem
                  onClick={async (e) => {
                     e.stopPropagation();
                     onClickDelete(rulesInfraction);
                     setAnchorElUser(null);
                  }}
               >
                  <ListItemIcon>
                     <DeleteForeverIcon color={"error"} fontSize="small" />
                  </ListItemIcon>
                  <Typography>Delete</Typography>
               </MenuItem>
            )}
         </Menu>
      </Box>
   );
}
