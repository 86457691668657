import { useMemo } from "react";
import { useAxiosInstance } from "./useAxiosInstance";
import useRequest from "./useRequest";
import { Maybe } from "../TYPE";
import { RegistrationSummary } from "./useTournamentApi";
import { EmbeddedResponse, PageableQueryParams, PageableResponse } from "./useApplicationApi";

export function useRulesInfractionApi() {
   const instance = useAxiosInstance();

   const api = {
      createRulesInfraction: useRequest<RulesInfractionSummary, never, CreateRulesInfractionRequest, never>(
         useMemo(
            () => ({
               url: "/rulesInfractions",
               method: "post",
            }),
            []
         ),
         instance
      ),
      updateRulesInfraction: useRequest<RulesInfractionSummary, { id: string }, UpdateRulesInfractionRequest, never>(
         useMemo(
            () => ({
               url: "/rulesInfractions/{id}",
               method: "post",
            }),
            []
         ),
         instance
      ),
      deleteRulesInfraction: useRequest<never, { id: string }, never, never>(
         useMemo(
            () => ({
               url: "/rulesInfractions/{id}",
               method: "delete",
            }),
            []
         ),
         instance
      ),
      searchRulesInfractions: useRequest<
         PageableResponse<{
            rulesInfractions: RulesInfractionSummary[];
         }>,
         never,
         never,
         RulesInfractionSearchQueryParams
      >(
         useMemo(
            () => ({
               url: "/rulesInfractions/search/byTournamentIdAndFilter/",
               method: "get",
               queryParamsDefault: {
                  tournamentId: "",
                  projection: "summary",
                  size: 25,
                  page: 0,
                  sort: "",
               },
            }),
            []
         ),
         instance
      ),
      getAllRulesInfractionTypes: useRequest<
         EmbeddedResponse<{
            rulesInfractionTypes: RulesInfractionTypeSummary[];
         }>,
         never,
         never,
         never
      >(
         useMemo(
            () => ({
               url: "/rulesInfractionTypes",
               method: "get",
            }),
            []
         ),
         instance
      ),
   };
   const loading = Object.values(api).some((r) => Boolean(r.loading));
   return { ...api, loading };
}

export interface RulesInfractionTypeSummary {
   id: string;
   name: string;
   defaultPenalty: Penalty;
   parent: RulesInfractionTypeNameOnly;
}

export interface RulesInfractionTypeNameOnly {
   id: string;
   name: string;
}

export interface RulesInfractionSummary {
   id: string;
   description: string;
   penalty: Penalty;
   rulesInfractionType: RulesInfractionTypeSummary;
   registration: RegistrationSummary;
}

export interface RulesInfractionSearchQueryParams extends PageableQueryParams {
   tournamentId: string;
   searchText?: Maybe<string>;
   projection?: string;
}

export type Penalty = "NONE" | "WARNING" | "GAME_LOSS" | "MATCH_LOSS" | "DISQUALIFICATION";

export interface CreateRulesInfractionRequest {
   registrationId: string;
   rulesInfractionTypeId: string;
   penalty: Penalty;
   description: Maybe<string>;
}

export interface UpdateRulesInfractionRequest {
   infractionTypeId: string;
   penalty: Penalty;
   description: Maybe<string>;
}
