import React, { useEffect, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   IconButton,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TablePagination,
   TableRow,
   Typography,
   useTheme,
} from "@mui/material";
import { empty } from "../../utils";
import { RemoteTournament } from "../useRemoteTournament";
import CommandersCell from "../viewPage/matches/editDialog/CommandersCell";
import { Maybe } from "../../TYPE";
import RegistrationMenu from "./RegistrationMenu";
import useReload from "../../header/useReload";
import { RegistrationSummary, useTournamentApi } from "../../rest.client/useTournamentApi";
import PlayerTournamentCardDialog from "./PlayerTournamentCardDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RegistrationChip from "./RegistrationChip";
import TournamentRegistrationDialog from "./TournamentRegistrationDialog";
import DecklistLock from "./DecklistLock";
import TablePaginationActions from "../../gameFeatures/myGamesPage/TablePagingActions";
import BackDropSpinner from "../../common/BackDropSpinner";
import TagChip from "../viewPage/tags/TagChip";
import { tagComparator } from "../viewPage/tags/TagsExpander";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AddTagsPopover from "./AddTagsPopover";
import MiniStatusAlert from "./MiniStatusAlert";
import PeopleIcon from "@mui/icons-material/People";

export default function RegistrationListExpander({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const {
      getRegistrationsByTournamentId: { call: getRegistrations, responseData: registrationsResponse },
      loading,
   } = useTournamentApi();
   const tournamentFull = remoteTournament.tournament;
   const id = remoteTournament.id;
   const theme = useTheme();
   const { reload } = useReload("registration");
   const loadTags = remoteTournament.loadTags;
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(25);
   const [selectedRegForCardDialog, setSelectedRegForCardDialog] = useState<Maybe<RegistrationSummary>>();
   const [selectedRegForTagAdd, setSelectedRegForTagAdd] = useState<Maybe<string>>();
   const [openEditRegistration, setOpenRegistration] = useState(false);
   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
   const regList = registrationsResponse?._embedded.registrations || [];
   const targetForAddTag = regList.find((r) => r.id === selectedRegForTagAdd);

   const pageData =
      registrationsResponse != null
         ? registrationsResponse.page
         : {
              size: 0,
              totalElements: 0,
              totalPages: 0,
              number: 0,
           };

   useEffect(() => {
      if (id != null) {
         getRegistrations({
            queryParams: {
               tournamentId: id,
               page: page,
               size: rowsPerPage,
               sort: "createDate,desc",
            },
         });
      }
   }, [page, rowsPerPage, reload, id, getRegistrations]);

   useEffect(() => {
      if (remoteTournament.tags == null) {
         loadTags();
      }
   }, [loadTags, remoteTournament.tags]);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   return (
      <Accordion defaultExpanded={false}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <PeopleIcon />
               <Typography>Registered players</Typography>
               {regList.length === 0 && <Alert severity={"warning"}>No players registered!</Alert>}
            </Box>
         </AccordionSummary>
         <AccordionDetails sx={{ overflow: "auto" }}>
            <BackDropSpinner show={loading} />
            {anchorEl && targetForAddTag && remoteTournament.canEdit && (
               <AddTagsPopover
                  remoteTournament={remoteTournament}
                  registrationWithTags={targetForAddTag}
                  anchor={anchorEl}
                  close={() => setAnchorEl(null)}
               />
            )}
            {tournamentFull && (
               <Paper>
                  <Box>
                     <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={pageData.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                           inputProps: {
                              "aria-label": "rows per page",
                           },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                     />
                  </Box>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Player</TableCell>
                           <TableCell>Commander</TableCell>
                           <TableCell>Status</TableCell>
                           <TableCell>Decklist lock</TableCell>
                           <TableCell> </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {regList.map((r) => (
                           <TableRow
                              sx={{ cursor: "pointer" }}
                              key={r.id}
                              onClick={() => setSelectedRegForCardDialog(r)}
                           >
                              <TableCell>
                                 <Box>
                                    <RegistrationChip registration={r} />
                                    {remoteTournament.isOrganiser && (
                                       <IconButton
                                          color={"secondary"}
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             setAnchorEl(e.currentTarget);
                                             setSelectedRegForTagAdd(r.id);
                                          }}
                                       >
                                          <LocalOfferIcon />
                                       </IconButton>
                                    )}

                                    <Box
                                       sx={{
                                          display: "flex",
                                          marginTop: theme.spacing(1),
                                          flexWrap: "wrap",
                                          gap: theme.spacing(1),
                                          maxWidth: "600px",
                                       }}
                                    >
                                       {r.tags.sort(tagComparator).map((tag) => (
                                          <TagChip
                                             tag={tag}
                                             key={tag.id}
                                             size={"small"}
                                             chipProps={{ clickable: false }}
                                          />
                                       ))}
                                    </Box>
                                 </Box>
                              </TableCell>
                              <CommandersCell commander1={r.commander1} commander2={r.commander2} onClick={empty} />
                              <TableCell>
                                 <MiniStatusAlert registration={r} />
                              </TableCell>
                              <TableCell>
                                 <DecklistLock registration={r} />
                              </TableCell>
                              <TableCell>
                                 <RegistrationMenu
                                    registration={r}
                                    remoteTournament={remoteTournament}
                                    onClickViewReg={(reg) => setSelectedRegForCardDialog(reg)}
                                    onClickEditReg={() => setOpenRegistration(true)}
                                 />
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </Paper>
            )}
         </AccordionDetails>
         {selectedRegForCardDialog && (
            <PlayerTournamentCardDialog
               open={true}
               close={() => setSelectedRegForCardDialog(null)}
               remoteTournament={remoteTournament}
               registration={selectedRegForCardDialog}
            />
         )}
         <TournamentRegistrationDialog
            remoteTournament={remoteTournament}
            open={openEditRegistration}
            tournamentId={""}
            closeHandler={() => setOpenRegistration(false)}
         />
      </Accordion>
   );
}
