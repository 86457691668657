import React, { useCallback, useEffect, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Button,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TablePagination,
   TableRow,
   TextField,
   Typography,
   useTheme,
} from "@mui/material";
import { RemoteTournament } from "../useRemoteTournament";
import useReload from "../../header/useReload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TablePaginationActions from "../../gameFeatures/myGamesPage/TablePagingActions";
import { RulesInfractionSummary, useRulesInfractionApi } from "../../rest.client/useRulesInfractionApi";
import RegistrationChip from "../registration/RegistrationChip";
import CreateOrEditRulesInfractionDialog from "./CreateOrEditRulesInfractionDialog";
import GavelIcon from "@mui/icons-material/Gavel";
import { Maybe } from "../../TYPE";
import BackDropSpinner from "../../common/BackDropSpinner";
import RulesInfractionMenu from "./RulesInfractionMenu";
import OkCancelDialog from "../../OkCancelDialog";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";

export default function RulesInfractionsExpander({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const {
      searchRulesInfractions: { call: searchRulesInfractions, responseData: rulesInfractionsResponse },
      deleteRulesInfraction: { call: deleteInfraction },
      loading,
   } = useRulesInfractionApi();
   const tournamentFull = remoteTournament.tournament;
   const id = remoteTournament.id;
   const theme = useTheme();
   const showMessage = useAddMessageItem();
   const { reload, requestReload } = useReload("rulesInfractions");
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(25);
   const rulesInfractions = rulesInfractionsResponse?._embedded.rulesInfractions || [];
   const [open, setOpen] = useState(false);
   const [openCreateRulesInfraction, setOpenCreateRulesInfraction] = useState(false);
   const [searchActiveTimeout, setSearchActiveTimeout] = useState<Maybe<number>>(null);
   const [searchText, setSearchText] = useState<Maybe<string>>(null);
   const [rulesInfractionToEdit, setRulesInfractionToEdit] = useState<Maybe<RulesInfractionSummary>>(null);
   const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);
   const [rulesInfractionToDelete, setRulesInfractionToDelete] = useState<Maybe<RulesInfractionSummary>>(null);

   const pageData =
      rulesInfractionsResponse != null
         ? rulesInfractionsResponse.page
         : {
              size: 0,
              totalElements: 0,
              totalPages: 0,
              number: 0,
           };

   useEffect(() => {
      if (id != null && open) {
         searchRulesInfractions({
            queryParams: {
               tournamentId: id,
               page: page,
               searchText: searchText,
               size: rowsPerPage,
               sort: "createDate,desc",
            },
         });
      }
   }, [page, rowsPerPage, reload, id, searchRulesInfractions, open, searchText]);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const onClickEdit = useCallback(
      (rulesInfraction: RulesInfractionSummary) => {
         setRulesInfractionToEdit(rulesInfraction);
         setReadOnlyMode(false);
      },
      [setRulesInfractionToEdit]
   );

   return (
      <Accordion onChange={() => setOpen(!open)} sx={{ width: "100%" }}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <GavelIcon />
               <Typography>Rules enforcement center</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails sx={{ overflow: "auto" }}>
            <BackDropSpinner show={loading} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
               <Box>
                  <Button variant={"contained"} onClick={() => setOpenCreateRulesInfraction(true)}>
                     Add rules infraction
                  </Button>
               </Box>
               {tournamentFull && (
                  <Paper>
                     <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        <TextField
                           size={"small"}
                           variant={"outlined"}
                           defaultValue={""}
                           label={"Search"}
                           sx={{ minWidth: "200px", flexGrow: 1 }}
                           onChange={(ev) => {
                              if (searchActiveTimeout != null) {
                                 clearTimeout(searchActiveTimeout);
                                 setSearchActiveTimeout(null);
                              }
                              const timeOutId = window.setTimeout(() => {
                                 setSearchText(ev.target.value);
                              }, 500);
                              setSearchActiveTimeout(timeOutId);
                           }}
                        />
                        <TablePagination
                           component={"div"}
                           rowsPerPageOptions={[10, 25, 50, 100]}
                           count={pageData.totalElements}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           SelectProps={{
                              inputProps: {
                                 "aria-label": "rows per page",
                              },
                           }}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           ActionsComponent={TablePaginationActions}
                        />
                     </Box>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell>Player</TableCell>
                              <TableCell>Infraction</TableCell>
                              <TableCell>Penalty</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell></TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rulesInfractions.map((infraction) => (
                              <TableRow
                                 sx={{ cursor: "pointer" }}
                                 key={infraction.id}
                                 onClick={() => {
                                    setReadOnlyMode(true);
                                    setRulesInfractionToEdit(infraction);
                                 }}
                              >
                                 <TableCell>
                                    <RegistrationChip registration={infraction.registration} />
                                 </TableCell>
                                 <TableCell>{infraction.rulesInfractionType.name}</TableCell>
                                 <TableCell>{infraction.penalty}</TableCell>
                                 <TableCell>
                                    <Typography
                                       maxWidth={"200px"}
                                       overflow={"hidden"}
                                       textOverflow={"ellipsis"}
                                       whiteSpace={"nowrap"}
                                    >
                                       {infraction.description}
                                    </Typography>
                                 </TableCell>
                                 <TableCell>
                                    <RulesInfractionMenu
                                       rulesInfraction={infraction}
                                       onClickDelete={() => setRulesInfractionToDelete(infraction)}
                                       onClickEdit={onClickEdit}
                                    />
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                     {rulesInfractions.length === 0 && <Alert severity={"info"}>No infractions found!</Alert>}
                  </Paper>
               )}
            </Box>
         </AccordionDetails>
         {(openCreateRulesInfraction || rulesInfractionToEdit != null) && (
            <CreateOrEditRulesInfractionDialog
               open={true}
               readOnly={readOnlyMode}
               onClickEdit={onClickEdit}
               remoteTournament={remoteTournament}
               existingRulesInfraction={rulesInfractionToEdit}
               closeHandler={() => {
                  setOpenCreateRulesInfraction(false);
                  setRulesInfractionToEdit(null);
                  setReadOnlyMode(false);
               }}
            />
         )}
         <OkCancelDialog
            open={rulesInfractionToDelete != null}
            onCancel={() => setRulesInfractionToDelete(null)}
            onOk={async () => {
               if (rulesInfractionToDelete) {
                  await deleteInfraction({ pathParams: { id: rulesInfractionToDelete.id } });
                  setRulesInfractionToDelete(null);
                  showMessage("Infraction deleted successfully.", "success");
                  requestReload();
               }
            }}
            title={"Delete rules infraction"}
            message={
               "Are you sure you want to delete? " +
               rulesInfractionToDelete?.registration.user.name +
               " -- " +
               rulesInfractionToDelete?.rulesInfractionType.name
            }
         />
      </Accordion>
   );
}
