import React, { useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { Procedure } from "../../../utils";
import { useDeckListsApi } from "../../../rest.client/useDeckListApi";
import DecklistInfoCard from "../../registration/DecklistInfoCard";
import Spinner from "../../../common/Spinner";

export default function DecklistDetailsDialog({
   open,
   onClose,
   snapShotId,
}: {
   open: boolean;
   onClose: Procedure;
   snapShotId: string;
}) {
   const {
      getSnapshotAllData: { call: getSnapshotCall, responseData: snapshotData },
      loading,
   } = useDeckListsApi();
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   useEffect(() => {
      if (snapshotData == null && open) {
         getSnapshotCall({ pathParams: { id: snapShotId } });
      }
   }, [getSnapshotCall, open, snapShotId, snapshotData]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         maxWidth={"sm"}
         fullWidth={true}
         fullScreen={smallScreen}
         sx={{ minWidth: "200px" }}
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               onClose();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Decklist details</DialogTitle>
         <DialogContent>
            <Box>
               <Spinner show={loading} />
            </Box>
            <Box>{snapshotData && <DecklistInfoCard snapshot={snapshotData} />}</Box>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color={"secondary"}>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   );
}
