import React, { ReactNode } from "react";
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { Maybe } from "../../../../TYPE";
import { RegistrationSummary } from "../../../../rest.client/useTournamentApi";
import { Consumer } from "../../../../utils";
import { AlertColor } from "@mui/material/Alert/Alert";
import { SxProps } from "@mui/system";

export interface PlayerSelectionStatus {
   severity: AlertColor;
   message: ReactNode;
}

export default function RegistrationSelector({
   reg,
   setRegistration,
   inUseRegs,
   readOnly,
   registrations = [],
   label,
   selectionValidator,
   selectSx,
   fullWidth,
   acceptedOnly = true,
}: {
   reg: Maybe<RegistrationSummary>;
   setRegistration: Consumer<RegistrationSummary>;
   inUseRegs: RegistrationSummary[];
   registrations?: RegistrationSummary[];
   readOnly: boolean;
   label?: string;
   selectionValidator?: (r: RegistrationSummary) => PlayerSelectionStatus;
   acceptedOnly?: boolean;
   selectSx?: SxProps;
   fullWidth?: boolean;
}) {
   const theme = useTheme();
   const filteredRegs = registrations.filter(
      (r) =>
         reg?.id === r.id ||
         ((!acceptedOnly || r.status === "ACCEPTED") && !inUseRegs.some((inUse) => r.id === inUse.id))
   );
   const labelText = label == null ? "Select player" : label;
   return (
      <Box sx={{ display: "flex", padding: theme.spacing(1) }}>
         <FormControl fullWidth={fullWidth}>
            <InputLabel id="demo-simple-select-label">{labelText}</InputLabel>
            <Select
               size={"medium"}
               sx={{ width: 200, ...selectSx }}
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={reg?.id || ""}
               label={labelText}
               disabled={readOnly}
               onChange={(e) => {
                  const target = registrations?.find((r) => r.id === (e.target.value as string));
                  target && setRegistration(target);
               }}
            >
               {filteredRegs.map((r) => {
                  const validationResult = selectionValidator ? selectionValidator(r) : null;
                  return (
                     <MenuItem value={r.id} key={r.id}>
                        <Box
                           sx={{
                              display: "flex",
                              gap: theme.spacing(1),
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                           }}
                        >
                           <Typography
                              maxWidth={"160px"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                           >
                              {r.user.name}
                           </Typography>
                           {validationResult && !readOnly && (
                              <Alert sx={{ width: 120 }} severity={validationResult.severity}>
                                 {validationResult.message}
                              </Alert>
                           )}
                        </Box>
                     </MenuItem>
                  );
               })}
            </Select>
         </FormControl>
      </Box>
   );
}
