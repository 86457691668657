import React from "react";
import { Alert, Box, MenuItem, TextField, useTheme } from "@mui/material";
import { getUserFriendlyNameForTieBreakers, TieBreakerSystem } from "../../../rest.client/useStagesApi";
import { Consumer } from "../../../utils";
import HelpIcon from "../../../common/HelpIcon";

export default function TieBreakerSystemDropdown({
   setError,
   onChange,
   tiebreakerSystem,
}: {
   tiebreakerSystem: TieBreakerSystem;
   setError: (key: string, isInError: boolean) => void;
   onChange: Consumer<TieBreakerSystem>;
}) {
   const theme = useTheme();
   return (
      <Box>
         {tiebreakerSystem === "MTR_3_1" && (
            <Alert severity={"warning"}>
               MTR 3.1 should only be used if you intend to play multiple games per match. If you are running best of 1
               then please use MSTR or MTRA instead.
            </Alert>
         )}
         <TextField
            fullWidth
            required
            select
            sx={{ marginTop: "10px" }}
            variant={"outlined"}
            label={"Tiebreaker system"}
            value={tiebreakerSystem}
            onChange={(e) => {
               const selection = e.target.value as TieBreakerSystem;
               onChange(selection);
            }}
            inputProps={{ maxLength: 100 }}
         >
            <MenuItem key={""} value={"MAMTR_3_1"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForTieBreakers("MAMTR_3_1")}
                  <HelpIcon
                     message={
                        "Tiebreaker system defined in the MAMTR documentation see here for details https://juizes-mtg-portugal.github.io/." +
                        "This system uses 4 metrics to rank players. Points > Player match win % > Opponent average match points > Opponent match win %." +
                        "This system is very effective and is the standard for all Brainstorm tournaments."
                     }
                  />
               </Box>
            </MenuItem>
            <MenuItem key={""} value={"MTRA_3_1"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForTieBreakers("MTRA_3_1")}
                  <HelpIcon
                     message={
                        "Tiebreaker system commonly used on the topdeck system, it uses an adjusted Opponent Match Win % as its only additional metric."
                     }
                  />
               </Box>
            </MenuItem>
            <MenuItem key={""} value={"BRAINSTORM_WBE"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForTieBreakers("BRAINSTORM_WBE")}
                  <HelpIcon
                     message={
                        "A collision free tiebreaker system developed by Brainstorm. Metrics used are Points > Wins > Buchholz score > Elo. " +
                        "This can provide effectively collision free ranking however it does this using a full precision Elo rating which would typically" +
                        " be considered outside the tournament context."
                     }
                  />
               </Box>
            </MenuItem>
            <MenuItem key={""} value={"MTR_3_1"}>
               <Box sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
                  {getUserFriendlyNameForTieBreakers("MTR_3_1")}
                  <HelpIcon
                     message={
                        "This is the base MTR tiebreaker used by Wizards for all sanctioned events. It is effective for best of 3 or above."
                     }
                  />
               </Box>
            </MenuItem>
         </TextField>
      </Box>
   );
}
