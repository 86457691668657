import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import useGlobal from "../../../global/useGlobal";
import { Maybe, TPoint2 } from "../../../TYPE";
import { randomPos } from "../../../utils";
import { useParams } from "react-router-dom";
import UnstructuredLeagueView from "./league/UnstructuredLeagueView";
import { RemoteTournament } from "../../useRemoteTournament";
import StageOrganiserControls from "./StageOrganiserControls";
import useRemoteStage from "./useRemoteStage";
import SwissStageView from "./swiss/SwissStageView";
import SingleEliminationStageView from "./singleElimination/SingleEliminationStageView";
import StageDetailsExpander from "./StageDetailsExpander";
import BackDropSpinner from "../../../common/BackDropSpinner";
import QualifiedPlayersExpander from "./QualifiedPlayersExpander";

export default function StageView({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const [, setTilesPos] = useGlobal<Maybe<TPoint2>>("tilesPoints", null);
   const { stageId } = useParams();
   const theme = useTheme();
   const remoteStage = useRemoteStage(remoteTournament, stageId);

   useEffect(() => {
      setTilesPos(randomPos());
   }, [setTilesPos]);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
         }}
      >
         <BackDropSpinner show={remoteStage.loading} />
         {remoteStage.stage && (
            <>
               <StageDetailsExpander remoteTournament={remoteTournament} remoteStage={remoteStage} />
               <QualifiedPlayersExpander remoteStage={remoteStage} />
               {remoteTournament.canEdit && (
                  <StageOrganiserControls remoteStage={remoteStage} remoteTournament={remoteTournament} />
               )}
               {remoteStage.stage.unstructuredLeagueStage && (
                  <UnstructuredLeagueView stageFull={remoteStage.stage} remoteStage={remoteStage} />
               )}
               {remoteStage.stage.swissLeagueStage && (
                  <SwissStageView stageFull={remoteStage.stage} remoteStage={remoteStage} />
               )}
               {remoteStage.stage.singleEliminationStage && (
                  <SingleEliminationStageView stageFull={remoteStage.stage} remoteStage={remoteStage} />
               )}
            </>
         )}
      </Box>
   );
}
