import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import RegistrationSelector from "../../matches/editDialog/RegistrationSelector";
import useRemoteRound from "../useRemoteRound";
import { RegistrationSummary } from "../../../../rest.client/useTournamentApi";
import { Maybe } from "../../../../TYPE";
import { RemoteStage } from "../useRemoteStage";
import Spinner from "../../../../common/Spinner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RoundFullResponse } from "../../../../rest.client/useStagesApi";
import RegistrationChip from "../../../registration/RegistrationChip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useReload from "../../../../header/useReload";
import { MdWavingHand } from "react-icons/md";
import HelpIcon from "../../../../common/HelpIcon";

export default function ByesExpander({ remoteStage, round }: { remoteStage: RemoteStage; round: RoundFullResponse }) {
   const theme = useTheme();
   const remoteRound = useRemoteRound(remoteStage, round);
   const { reload } = useReload();
   const loadByes = remoteRound.loadByes;

   const [selectedReg, setSelectedReg] = useState<Maybe<RegistrationSummary>>();

   useEffect(() => {
      loadByes();
   }, [loadByes, reload]);

   return (
      <Accordion defaultExpanded={false}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <MdWavingHand size={"20px"} />
               <Typography>{"Round " + remoteRound.round.roundNumber + " byes "}</Typography>
               <HelpIcon
                  message={
                     "When a player is allocated a bye they will not have played a match this round." +
                     " They will receive points based on the stage point configuration." +
                     "Typically you will not need to manually adjust these during swiss stages."
                  }
               />
               <Alert
                  severity={
                     remoteRound.byes.length === 0 ? "success" : remoteRound.byes.length > 2 ? "warning" : "info"
                  }
               >
                  {remoteRound.byes.length} byes allocated
               </Alert>
            </Box>
         </AccordionSummary>
         {remoteStage.remoteTournament.tournament && (
            <AccordionDetails>
               <>
                  <Box sx={{ margin: theme.spacing(1) }}>
                     {remoteStage.canEdit && remoteRound.canAddByes && !remoteRound.loading && (
                        <Box sx={{ display: "flex" }}>
                           <RegistrationSelector
                              readOnly={!remoteStage.remoteTournament.isOrganiser}
                              inUseRegs={remoteRound.byes}
                              reg={selectedReg}
                              setRegistration={setSelectedReg}
                              registrations={remoteStage.remoteTournament.tournament.registrations}
                           />
                           {
                              <Button
                                 onClick={async () => {
                                    remoteStage.remoteTournament.tournament &&
                                       selectedReg &&
                                       (await remoteRound.addBye(selectedReg.id));
                                    setSelectedReg(null);
                                 }}
                                 disabled={selectedReg == null}
                              >
                                 Add bye
                              </Button>
                           }
                        </Box>
                     )}
                     <Spinner show={remoteRound.loading} />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
                     {remoteRound.byes.map((b) => {
                        return (
                           <Box alignItems="flex-start">
                              <RegistrationChip registration={b}>
                                 {remoteStage.canEdit && (
                                    <Button
                                       onClick={async () => {
                                          await remoteRound.deleteBye(b.id);
                                       }}
                                    >
                                       <DeleteForeverIcon />
                                    </Button>
                                 )}
                              </RegistrationChip>
                           </Box>
                        );
                     })}
                  </Box>
                  {remoteRound.byes.length === 0 && <Alert severity={"info"}>No byes allocated for this round.</Alert>}
               </>
            </AccordionDetails>
         )}
      </Accordion>
   );
}
