import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { Consumer, Procedure } from "../../utils";
import CustomOrTemplateOptions, { CreationFlowType } from "./CustomOrTemplateOptions";

export default function TournamentCreationDialog({
   open,
   close,
   onSelectFlowType,
}: {
   open: boolean;
   close: Procedure;
   onSelectFlowType: Consumer<CreationFlowType>;
}) {
   const theme = useTheme();
   const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   return (
      <Dialog
         open={open}
         fullScreen={smallScreen}
         maxWidth={"sm"}
         fullWidth={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">Select creation type</DialogTitle>
         <DialogContent>
            <CustomOrTemplateOptions
               onSelectFlowType={(flowType) => {
                  onSelectFlowType(flowType);
                  close();
               }}
            />
         </DialogContent>
         <DialogActions>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
