import React from "react";
import {
   Avatar,
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CardHeader,
   List,
   ListItem,
   ListItemText,
   Paper,
   useTheme,
} from "@mui/material";
import { TournamentWinnerFullResp } from "../../../rest.client/useTournamentApi";
import { Standing } from "../../../rest.client/useStagesApi";
import { Procedure } from "../../../utils";

export default function WinnerCard({
   winner,
   standing,
   onClickViewDecklist,
   onClickShowDetails,
}: {
   winner: TournamentWinnerFullResp;
   standing: Standing;
   onClickViewDecklist: Procedure;
   onClickShowDetails: Procedure;
}) {
   const theme = useTheme();
   const registration = winner.registration;

   return (
      <Box sx={{ width: "300px", minWidth: "300px" }}>
         <Card>
            <Paper elevation={2} sx={{ padding: theme.spacing(1) }}>
               <Box sx={{ display: "flex", justifyContent: "center", gap: theme.spacing(1) }}>
                  <Avatar src={registration.commander1.artCropImageUri}></Avatar>
                  {registration.commander2 && <Avatar src={registration.commander2.artCropImageUri}></Avatar>}
               </Box>
               <CardHeader sx={{ textAlign: "center" }} title={registration.user.name} />
            </Paper>
            <Paper elevation={2}>
               <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                     <List dense={true} sx={{}}>
                        <ListItem>
                           <ListItemText primary={standing.wins} secondary={"Wins"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText primary={standing.losses} secondary={"Losses"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText primary={standing.draws} secondary={"Draws"} />
                        </ListItem>
                     </List>
                     <List dense={true} sx={{}}>
                        <ListItem>
                           <ListItemText primary={standing.pointsTotal} secondary={"Points"} />
                        </ListItem>
                        <ListItem>
                           <ListItemText
                              primary={(
                                 (standing.wins / (standing.wins + standing.losses + standing.draws)) *
                                 100
                              ).toFixed(2)}
                              secondary={"W%"}
                           />
                        </ListItem>
                     </List>
                  </Box>
               </CardContent>
            </Paper>
            <CardActions>
               <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <Box>
                     {winner.registration.decklistSnapshotId != null && (
                        <Button variant={"outlined"} size="medium" onClick={onClickViewDecklist}>
                           Decklist
                        </Button>
                     )}
                  </Box>
                  <Button variant={"outlined"} size="medium" onClick={onClickShowDetails}>
                     Details
                  </Button>
               </Box>
            </CardActions>
         </Card>
      </Box>
   );
}
