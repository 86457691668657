import React, { ReactNode, useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import { RulesInfractionTypeSummary, useRulesInfractionApi } from "../../rest.client/useRulesInfractionApi";
import { Consumer } from "../../utils";
import { Maybe } from "../../TYPE";

export interface PlayerSelectionStatus {
   severity: AlertColor;
   message: ReactNode;
}

export default function RulesTypeSelector({
   selectedType,
   setSelectedType,
   readOnly,
}: {
   selectedType: Maybe<RulesInfractionTypeSummary>;
   setSelectedType: Consumer<RulesInfractionTypeSummary>;
   readOnly: boolean;
}) {
   const theme = useTheme();
   const {
      getAllRulesInfractionTypes: { call: getAllTypes, responseData },
   } = useRulesInfractionApi();
   const types = responseData?._embedded.rulesInfractionTypes || [];

   useEffect(() => {
      if (!responseData) {
         getAllTypes();
      }
   }, [getAllTypes, responseData]);

   return (
      <Box sx={{ display: "flex", padding: theme.spacing(1) }}>
         <FormControl fullWidth={true}>
            <InputLabel>Select type</InputLabel>
            <Select
               fullWidth={true}
               value={selectedType?.id || ""}
               disabled={readOnly}
               label="Select type"
               onChange={(e) => {
                  const target = types?.find((type) => type.id === (e.target.value as string));
                  target && setSelectedType(target);
               }}
            >
               {types.map((rT) => {
                  return (
                     <MenuItem value={rT.id} key={rT.id}>
                        {(rT.parent ? rT.parent?.name + " - " : "") + rT.name}
                     </MenuItem>
                  );
               })}
            </Select>
         </FormControl>
      </Box>
   );
}
