import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography, useTheme } from "@mui/material";
import { RemoteTournament } from "../../useRemoteTournament";
import { useTournamentApi } from "../../../rest.client/useTournamentApi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PrizeTierCardRow from "./PrizeTierCardRow";

export default function ViewTournamentResultsExpander({
   remoteTournament,
   defaultOpened,
}: {
   remoteTournament: RemoteTournament;
   defaultOpened: boolean;
}) {
   const {
      getStandings: { call: getStandings, responseData: standings },
   } = useTournamentApi();
   const theme = useTheme();
   const [expanded, setExpanded] = useState(defaultOpened);

   const tiers = remoteTournament.tournament?.prizeTiers || [];

   useEffect(() => {
      if (expanded && standings == null && remoteTournament.id) {
         getStandings({ pathParams: { id: remoteTournament.id } });
      }
   }, [expanded, getStandings, remoteTournament.id, standings]);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <EmojiEventsIcon />
               <Typography>Final results</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            {standings && (
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     gap: theme.spacing(2),
                     maxHeight: "800px",
                     overflowY: "auto",
                  }}
               >
                  {tiers.map((tier) => (
                     <>
                        <PrizeTierCardRow tier={tier} standings={standings} remoteTournament={remoteTournament} />
                        <Divider />
                     </>
                  ))}
               </Box>
            )}
         </AccordionDetails>
      </Accordion>
   );
}
