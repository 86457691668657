import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Typography, useTheme } from "@mui/material";
import { RoundFullResponse } from "../../rest.client/useStagesApi";
import RoundStatusBanner from "../viewPage/stageView/RoundStatusBanner";
import RoundTimerCountdown from "./RoundTimerCountdown";
import RoundTimerDialog from "./RoundTimerDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";

export default function RoundDetails({ round }: { round: RoundFullResponse }) {
   const theme = useTheme();
   const [openTimerDialog, setOpenTimerDialog] = useState(false);
   const [roundsExpanded, setRoundsExpanded] = useState(true);

   return (
      <Accordion expanded={roundsExpanded} onChange={() => setRoundsExpanded(!roundsExpanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <InfoIcon />
               <Typography>{"Round " + round.roundNumber + " details"}</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            <Box sx={{ display: "flex", gap: theme.spacing(2), flexDirection: "column" }}>
               <RoundStatusBanner round={round} />
               {round.status === "ACTIVE" && round.roundEndDate != null && (
                  <Alert severity="warning" sx={{ cursor: "pointer" }} onClick={() => setOpenTimerDialog(true)}>
                     <Typography>Round timer:</Typography>
                     <RoundTimerCountdown round={round} />
                  </Alert>
               )}
               <RoundTimerDialog open={openTimerDialog} onClose={() => setOpenTimerDialog(false)} round={round} />
            </Box>
         </AccordionDetails>
      </Accordion>
   );
}
