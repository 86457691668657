import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Typography, useTheme } from "@mui/material";
import { RemoteStage } from "../useRemoteStage";
import { MatchResultWinnerOnlyResponse, RoundFullResponse } from "../../../../rest.client/useStagesApi";
import MatchesTable from "../../matches/MatchesTable";
import { toStageRoundMatchViewPage, toStageRoundViewPage } from "../../../../navPaths";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MatchControls from "../MatchControls";
import OkCancelDialog from "../../../../OkCancelDialog";
import { Maybe } from "../../../../TYPE";
import useRemoteRound from "../useRemoteRound";
import { GiCrossedSwords } from "react-icons/gi";
import HelpIcon from "../../../../common/HelpIcon";

export default function RoundMatchesExpander({
   round,
   remoteStage,
   selectedMatchId,
}: {
   round: RoundFullResponse;
   remoteStage: RemoteStage;
   selectedMatchId?: string;
}) {
   const nav = useNavigate();
   const theme = useTheme();
   const stage = remoteStage.stage;
   const tournament = remoteStage.remoteTournament.tournament;
   const remoteRound = useRemoteRound(remoteStage, round);

   const [matchToDelete, setMatchToDelete] = useState<Maybe<MatchResultWinnerOnlyResponse>>();

   return tournament != null && stage != null && round != null ? (
      <Accordion defaultExpanded={true}>
         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <GiCrossedSwords size={"20px"} />
               <Typography>{"Round " + round.roundNumber + " matches(pods)"}</Typography>
               <HelpIcon
                  message={
                     "This panel contains the list of all matches in this round. You can manually adjust these matches however" +
                     " typically it is recommended to auto-generate all matches first using te round controls. "
                  }
               />
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            {remoteStage.remoteTournament.isOrganiser && (
               <MatchControls remoteStage={remoteStage} selectedRound={round} />
            )}
            <Box>
               <MatchesTable
                  matches={round.matches}
                  remoteStage={remoteStage}
                  onClickMatch={(m) => nav(toStageRoundMatchViewPage(tournament?.id, stage?.id, round.id, m.id))}
                  onClose={() => nav(toStageRoundViewPage(tournament?.id, stage?.id, round.id))}
                  matchId={selectedMatchId}
                  showDelete={remoteRound.canDeleteMatch}
                  onClickDelete={(m) => setMatchToDelete(m)}
               />
            </Box>
         </AccordionDetails>
         {matchToDelete && (
            <OkCancelDialog
               open={true}
               onCancel={() => setMatchToDelete(null)}
               onOk={async () => {
                  await remoteRound.deleteMatch(matchToDelete.id);
                  setMatchToDelete(null);
               }}
               title={"Delete match?"}
               message={
                  <Box>
                     <Alert severity={"warning"}>
                        Deleting a match will permanently remove the record of the match and all games but will{" "}
                        <b>NOT</b> roll back Elo allocation previously applied from approved games.
                     </Alert>
                     <Typography sx={{ margin: theme.spacing(1) }}>
                        Are you sure you want to delete this match.
                     </Typography>
                  </Box>
               }
            />
         )}
      </Accordion>
   ) : (
      <></>
   );
}
