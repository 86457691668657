import { RemoteTournament } from "../useRemoteTournament";
import { RegistrationSummary } from "../../rest.client/useTournamentApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
   Penalty,
   RulesInfractionSummary,
   RulesInfractionTypeSummary,
   useRulesInfractionApi,
} from "../../rest.client/useRulesInfractionApi";
import { Maybe } from "../../TYPE";

export interface RemoteRulesInfraction {
   rulesInfractionData: {
      id: Maybe<string>;
      description: Maybe<string>;
      penalty: Penalty;
      rulesInfractionType: Maybe<RulesInfractionTypeSummary>;
      registration: Maybe<RegistrationSummary>;
   };
   setPenalty: (penalty: Penalty) => void;
   setDescription: (description: Maybe<string>) => void;
   setRulesInfractionType: (rulesInfractionType: RulesInfractionTypeSummary) => void;
   setRegistration: (registrationSummary: RegistrationSummary) => void;
   saveRulesInfraction: () => Promise<Maybe<RulesInfractionSummary>>;
}

export default function useRemoteRulesInfraction(
   remoteTournament: RemoteTournament,
   rulesInfraction?: RulesInfractionSummary
): RemoteRulesInfraction {
   const {
      createRulesInfraction: { call: create },
      updateRulesInfraction: { call: update },
   } = useRulesInfractionApi();
   const [penalty, setPenalty] = useState<Penalty>("NONE");
   const [description, setDescription] = useState<Maybe<string>>(null);
   const [registration, setRegistration] = useState<Maybe<RegistrationSummary>>();
   const [rulesInfractionType, setRulesInfractionType] = useState<RulesInfractionTypeSummary>();

   useEffect(() => {
      if (rulesInfraction) {
         setDescription(rulesInfraction.description);
         setPenalty(rulesInfraction.penalty);
         setRegistration(rulesInfraction.registration);
         setRulesInfractionType(rulesInfraction.rulesInfractionType);
      }
   }, [rulesInfraction]);

   const saveRulesInfraction = useCallback(async () => {
      if (registration && rulesInfractionType && rulesInfraction == null) {
         return await create({
            body: {
               penalty,
               description,
               registrationId: registration.id,
               rulesInfractionTypeId: rulesInfractionType.id,
            },
         });
      } else if (registration && rulesInfractionType && rulesInfraction != null) {
         return await update({
            pathParams: { id: rulesInfraction.id },
            body: {
               penalty,
               description,
               infractionTypeId: rulesInfractionType.id,
            },
         });
      }
   }, [create, description, penalty, registration, rulesInfraction, rulesInfractionType, update]);

   const setTypeWrapper = useCallback(
      (type: RulesInfractionTypeSummary) => {
         if (penalty == null) {
            setPenalty(type.defaultPenalty);
         }
         setRulesInfractionType(type);
      },
      [penalty]
   );

   return useMemo(
      () => ({
         rulesInfractionData: {
            id: rulesInfraction?.id,
            registration: registration,
            rulesInfractionType: rulesInfractionType,
            description: description,
            penalty: penalty,
         },
         saveRulesInfraction,
         setPenalty,
         setDescription,
         setRegistration,
         setRulesInfractionType: setTypeWrapper,
      }),

      [
         description,
         penalty,
         registration,
         rulesInfraction?.id,
         rulesInfractionType,
         saveRulesInfraction,
         setTypeWrapper,
      ]
   );
}
