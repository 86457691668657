import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RegistrationFull } from "../../rest.client/useTournamentApi";
import { RemoteTournament } from "../useRemoteTournament";
import useReload from "../../header/useReload";
import TagChip from "../viewPage/tags/TagChip";
import AddTagsPopover from "./AddTagsPopover";
import useRemoteRegistration from "./useRemoteRegistration";
import useAddMessageItem from "../../messagesComponent/useAddMessageItem";
import { tagComparator } from "../viewPage/tags/TagsExpander";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export default function RegistrationTagsExpander({
   remoteTournament,
   fullRegistration,
}: {
   remoteTournament: RemoteTournament;
   fullRegistration: RegistrationFull;
}) {
   const theme = useTheme();
   const [expanded, setExpanded] = useState(false);
   const { reload, requestReload } = useReload("registration");
   const showMessage = useAddMessageItem();
   const tags = fullRegistration.tags;
   const remoteRegistration = useRemoteRegistration(remoteTournament, fullRegistration);
   const loadTags = remoteTournament.loadTags;
   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   useEffect(() => {
      if (expanded) {
         loadTags();
      }
   }, [expanded, loadTags]);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <LocalOfferIcon />
               <Typography>Player tags</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            {remoteTournament.isOrganiser && (
               <Box sx={{ marginBottom: theme.spacing(2) }}>
                  <Button variant={"contained"} onClick={handleClick}>
                     Add tag
                  </Button>
                  <AddTagsPopover
                     remoteTournament={remoteTournament}
                     registrationWithTags={fullRegistration}
                     anchor={anchorEl}
                     close={() => setAnchorEl(null)}
                  />
               </Box>
            )}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: theme.spacing(2) }}>
               {tags.sort(tagComparator).map((tag) => (
                  <TagChip
                     tag={tag}
                     key={tag.id}
                     size={"medium"}
                     onDelete={
                        remoteTournament.canEdit
                           ? async (t) => {
                                await remoteRegistration.removeTag(t);
                                showMessage(t.name + " removed!", "success");
                                requestReload();
                             }
                           : undefined
                     }
                  />
               ))}
            </Box>
            {tags.length === 0 && <Alert severity={"info"}>No tags found!</Alert>}
         </AccordionDetails>
      </Accordion>
   );
}
