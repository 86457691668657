import React, { FC, memo, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Frame from "./Frame";
import TopPlayersAndCardsPage from "./insightsFeatures/pageTopCardsPlayers/TopPlayersAndCardsPage";
import Login from "./auth/Login";
import MyGamesPage from "./gameFeatures/myGamesPage/MyGamesPage";
import LogGamePage from "./gameFeatures/pageLogGame/LogGamePage";
import ProfilePage from "./userFeatures/profile/ProfilePage";
import ViewGamePage from "./gameFeatures/viewGamePage/ViewGamePage";
import TermsOfServicePage from "./terms/TermsOfServicePage";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import {
   toCommanderInsights,
   toLeaderBoard,
   toMetaInsights,
   toMyTournaments,
   toPlayerInsights,
   toPopularCommanders,
   toStageRoundMatchViewPage,
   toStageRoundViewPage,
   toStageViewPage,
   toStageViewPageMatchOpen,
   toTopPlayersAndCards,
   toTournamentDetails,
} from "./navPaths";
import PlayerInsightsPage from "./insightsFeatures/playerInsightsPage/PlayerInsightsPage";
import CommanderInsightsPage from "./insightsFeatures/commanderInsightsPage/CommanderInsightsPage";
import MetaInsightsPage from "./insightsFeatures/metaInsightsPage/MetaInsightsPage";
import {
   EDIT_GAME_PATH,
   HOME_PATH,
   LOG_GAME_PATH,
   LOGIN_PATH,
   MY_GAMES_PATH,
   PROFILE_PATH,
   VIEW_GAME_PATH,
} from "./index";
import DynamicTheme from "./DynamicTheme";
import TournamentsListPage from "./pageTournament/listPage/TournamentsListPage";
import ViewTournamentPage from "./pageTournament/viewPage/ViewTournamentPage";
import LeaderBoardPage from "./insightsFeatures/pageTopCardsPlayers/LeaderBoardPage";
import PopularCommandersPage from "./insightsFeatures/pageTopCardsPlayers/PopularCommandersPage";
import ShortUrlRedirectPage from "./pageTournament/ShortUrlRedirectPage";
import { CssBaseline } from "@mui/material";
import MyTournamentsPage from "./myTournamentsPage/MyTournamentsPage";

export const isTestApiEnabled =
   window.location.hostname.includes("localhost") || window.location.hostname.includes("test-server");

const App: FC = () => {
   //this redirect is to try to fix issues with google selecting the incorrect route as canonical
   useEffect(() => {
      if (window.location.hostname === "www.edhelo.com") {
         window.location.replace("https://brainstorm-tms.com");
      }
   }, []);
   return (
      <BrowserRouter>
         <DynamicTheme>
            <CssBaseline />
            <Frame>
               <Routes>
                  <Route path={HOME_PATH} element={<TournamentsListPage />} />
                  <Route path={LOGIN_PATH} element={<Login />} />
                  <Route path={MY_GAMES_PATH} element={<MyGamesPage />} />
                  <Route path={LOG_GAME_PATH} element={<LogGamePage />} />
                  <Route path={EDIT_GAME_PATH} element={<LogGamePage />} />
                  <Route path={PROFILE_PATH} element={<ProfilePage />} />
                  <Route path={toTournamentDetails(":tournamentId")} element={<ViewTournamentPage />} />
                  <Route path={"ev/:tournamentIdBase32"} element={<ShortUrlRedirectPage />} />
                  <Route path={toStageViewPage(":tournamentId", ":stageId")} element={<ViewTournamentPage />} />
                  <Route
                     path={toStageRoundViewPage(":tournamentId", ":stageId", ":roundId")}
                     element={<ViewTournamentPage />}
                  />
                  <Route
                     path={toStageRoundMatchViewPage(":tournamentId", ":stageId", ":roundId", ":matchId")}
                     element={<ViewTournamentPage />}
                  />
                  <Route
                     path={toStageViewPageMatchOpen(":tournamentId", ":stageId", ":matchId")}
                     element={<ViewTournamentPage />}
                  />
                  <Route path={toMyTournaments()} element={<MyTournamentsPage />} />
                  <Route path={VIEW_GAME_PATH + ":id"} element={<ViewGamePage />} />
                  <Route path={"/tos/"} element={<TermsOfServicePage />} />
                  <Route path={"/privacy/"} element={<PrivacyPolicy />} />
                  <Route path={toPlayerInsights(":uid")} element={<PlayerInsightsPage />} />
                  <Route path={toPlayerInsights("")} element={<PlayerInsightsPage />} />
                  <Route path={toCommanderInsights(":id1")} element={<CommanderInsightsPage />} />
                  <Route path={"/insights/commander/"} element={<CommanderInsightsPage />} />
                  <Route path={toCommanderInsights(":id1", ":id2")} element={<CommanderInsightsPage />} />
                  <Route path={toMetaInsights()} element={<MetaInsightsPage />} />
                  <Route path={toTopPlayersAndCards()} element={<TopPlayersAndCardsPage />} />
                  <Route path={toLeaderBoard()} element={<LeaderBoardPage />} />
                  <Route path={toPopularCommanders()} element={<PopularCommandersPage />} />
               </Routes>
            </Frame>
         </DynamicTheme>
      </BrowserRouter>
   );
};

export default memo(App);
