import React, { useEffect, useMemo, useState } from "react";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Alert,
   Box,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StageFullResponse, StandingsRegResponse, useStageApi } from "../../rest.client/useStagesApi";
import { RemoteTournament } from "../useRemoteTournament";
import RegistrationChip from "../registration/RegistrationChip";
import TierChip from "./prizeTiers/TierChip";
import BackDropSpinner from "../../common/BackDropSpinner";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export default function OverallStandingsTableExpander({ remoteTournament }: { remoteTournament: RemoteTournament }) {
   const {
      getStageFull: { call: getStageFull },
      loading,
   } = useStageApi();
   const [allStages, setAllStages] = useState<StageFullResponse[]>([]);
   const theme = useTheme();
   const [expanded, setExpanded] = useState(false);
   const resultTiers = remoteTournament.tournament?.prizeTiers || [];

   //also not ideal we prob need a request for standings on their own
   useEffect(() => {
      const fetchData = async () => {
         if (expanded && remoteTournament.tournament) {
            const responses = await Promise.all(
               remoteTournament.tournament.stages.map((s) => getStageFull({ pathParams: { id: s.id } }))
            );
            setAllStages(responses);
         }
      };
      fetchData();
   }, [getStageFull, expanded, remoteTournament.tournament, allStages.length]);

   const collectiveStanding = useMemo(() => {
      const allStandings = allStages.flatMap((s) => s.standings);
      const map: Record<string, StandingsRegResponse> = {};
      allStandings.forEach((s) => {
         if (map[s.registration.id] == null) {
            map[s.registration.id] = s;
         } else {
            const current = map[s.registration.id];
            current.standing.wins += s.standing.wins;
            current.standing.losses += s.standing.losses;
            current.standing.draws += s.standing.draws;
            current.standing.pointsTotal += s.standing.pointsTotal;
         }
      });
      return Object.values(map).sort((a, b) => b.standing.pointsTotal - a.standing.pointsTotal);
   }, [allStages]);

   return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
               <FormatListNumberedIcon />
               <Typography>Overall standings</Typography>
            </Box>
         </AccordionSummary>
         <AccordionDetails>
            <BackDropSpinner show={loading} />
            <Alert severity={"warning"}>
               This table is currently only ordered by points and should be used as a reference only! Please check each
               individual stage for exact standings.
            </Alert>
            <Paper sx={{ width: "100%", position: "relative" }}>
               <TableContainer component={Paper}>
                  <Table sx={{}} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Name</TableCell>
                           <TableCell>Wins</TableCell>
                           <TableCell>Losses</TableCell>
                           <TableCell>Draws</TableCell>
                           <TableCell>Points</TableCell>
                           <TableCell>Byes</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {collectiveStanding.map((standing) => {
                           return (
                              <TableRow key={standing.registration.id} sx={{ cursor: "pointer" }}>
                                 <TableCell sx={{ display: "flex", gap: theme.spacing(1), flexWrap: "wrap" }}>
                                    <RegistrationChip
                                       registration={standing.registration}
                                       onClickOpenRegistrationCard={true}
                                       remoteTournament={remoteTournament}
                                    />
                                    {resultTiers
                                       .filter((t) =>
                                          t.winners.some((w) => w.registration.id === standing.registration.id)
                                       )
                                       .map((t) => (
                                          <TierChip tier={t} />
                                       ))}
                                 </TableCell>
                                 <TableCell>{standing.standing.wins}</TableCell>
                                 <TableCell>{standing.standing.losses}</TableCell>
                                 <TableCell>{standing.standing.draws}</TableCell>
                                 <TableCell>{standing.standing.pointsTotal}</TableCell>
                                 <TableCell>{standing.standing.byes}</TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
                  {collectiveStanding.length === 0 && <Alert severity={"info"}>No standings data found.</Alert>}
               </TableContainer>
            </Paper>
         </AccordionDetails>
      </Accordion>
   );
}
