import React, { useCallback } from "react";
import { Box, Button, Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import {
   DEFAULT_BLUR,
   DEFAULT_PADDING_IN_PX,
   XLARGE_PADDING_IN_PX,
} from "../tournaments2dComponents/TOURNAMENTS_2D_CONST";
import useGlobal from "../../global/useGlobal";
import { TTournamentsPageState } from "../../TYPE";
import { GLOBAL_VAR_NAME } from "../../UI_CONST";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { tournamentsPageDefaultState } from "./TournamentCustomFlowDialog";
import { Procedure } from "../../utils";

const tournamentCreationPageNavigationRootSx: SxProps = {
   position: "relative",
   boxSizing: "border-box",
   left: 0,
   top: 0,
   width: "100%",
   maxHeight: "56px",
   padding: `${XLARGE_PADDING_IN_PX}px 0 ${DEFAULT_PADDING_IN_PX}px 0`,
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   columnGap: `${XLARGE_PADDING_IN_PX}px`,
   flexGrow: 1,
};
const buttonSx: SxProps = {
   backdropFilter: DEFAULT_BLUR,
};

const stepperSx: SxProps = {
   width: "500px",
   pointerEvents: "none",
};

const steps = ["Tournament info", "Settings", "Confirmation"];

const NavigationBar: React.FC<{ onClose: Procedure }> = ({ onClose }) => {
   const [tournamentsPageState] = useGlobal<TTournamentsPageState>(
      GLOBAL_VAR_NAME.TOURNAMENTS_DIALOG_STATE,
      tournamentsPageDefaultState
   );
   const isMobileDevice = !useMediaQuery("(min-width:600px)");

   const handleCancelButtonClick = useCallback(() => onClose(), [onClose]);

   return (
      <Box id={"tournamentCreationPageNavigationRoot"} sx={tournamentCreationPageNavigationRootSx}>
         <Button
            startIcon={<DoNotDisturbIcon />}
            sx={buttonSx}
            size={"small"}
            variant={"outlined"}
            onClick={handleCancelButtonClick}
         >
            {"Cancel"}
         </Button>
         {!isMobileDevice && (
            <Stepper nonLinear sx={stepperSx} activeStep={tournamentsPageState.creationPageCurrentPanelIndex}>
               {steps.map((label) => (
                  <Step key={label}>
                     <StepLabel>{label}</StepLabel>
                  </Step>
               ))}
            </Stepper>
         )}
      </Box>
   );
};

export default NavigationBar;
