import React, { useState } from "react";
import { Alert, Box, Typography, useTheme } from "@mui/material";
import {
   PrizeTierFullResp,
   TournamentStandingsResponse,
   TournamentWinnerFullResp,
} from "../../../rest.client/useTournamentApi";
import WinnerCard from "./WinnerCard";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { EMPTY_STANDING } from "../../../rest.client/useStagesApi";
import { Maybe } from "../../../TYPE";
import DecklistDetailsDialog from "./DecklistDetailsDialog";
import PlayerTournamentCardDialog from "../../registration/PlayerTournamentCardDialog";
import { RemoteTournament } from "../../useRemoteTournament";

export default function PrizeTierCardRow({
   tier,
   remoteTournament,
   standings,
}: {
   tier: PrizeTierFullResp;
   standings: TournamentStandingsResponse;
   remoteTournament: RemoteTournament;
}) {
   const theme = useTheme();
   const [deckToView, setDeckToView] = useState<Maybe<TournamentWinnerFullResp>>();
   const [cardToView, setCardToView] = useState<Maybe<TournamentWinnerFullResp>>();

   return (
      <Box>
         <Box
            sx={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               gap: theme.spacing(0.5),
               marginBottom: theme.spacing(2),
            }}
         >
            <EmojiEventsIcon color={tier.tier === 1 ? "primary" : "secondary"} />
            <Typography color={tier.tier === 1 ? "primary" : "secondary"} fontWeight={"bold"} variant={"h5"}>
               {tier.name}
            </Typography>
            <EmojiEventsIcon color={tier.tier === 1 ? "primary" : "secondary"} />
         </Box>
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               flexWrap: "wrap",
               width: "100%",
               gap: theme.spacing(2),
            }}
         >
            {tier.winners.map((winner) => {
               const standingResponse = standings.standings.find((s) => s.registration.id === winner.registration.id);
               const standing = standingResponse?.standing || EMPTY_STANDING;
               return (
                  <WinnerCard
                     winner={winner}
                     standing={standing}
                     onClickViewDecklist={() => setDeckToView(winner)}
                     onClickShowDetails={() => setCardToView(winner)}
                  />
               );
            })}
         </Box>
         {tier.winners.length === 0 && <Alert severity={"warning"}>No players assigned to this result tier!</Alert>}
         {deckToView?.registration.decklistSnapshotId != null && (
            <DecklistDetailsDialog
               open={deckToView != null}
               onClose={() => setDeckToView(null)}
               snapShotId={deckToView.registration.decklistSnapshotId}
            />
         )}
         {cardToView && (
            <PlayerTournamentCardDialog
               open={true}
               close={() => setCardToView(null)}
               remoteTournament={remoteTournament}
               registration={cardToView?.registration}
            />
         )}
      </Box>
   );
}
