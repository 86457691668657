import React from "react";
import { Button, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Consumer } from "../../utils";
import TuneIcon from "@mui/icons-material/Tune";
import AssignmentIcon from "@mui/icons-material/Assignment";

export default function CustomOrTemplateOptions({
   onSelectFlowType,
}: {
   onSelectFlowType: Consumer<CreationFlowType>;
}) {
   const theme = useTheme();

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: theme.spacing(1),
         }}
      >
         <Button sx={{ width: 200 }} variant={"outlined"} onClick={() => onSelectFlowType("TEMPLATE")}>
            <Box>
               <AssignmentIcon sx={{ fontSize: "72px" }} />
               <Box>Simple - From template</Box>
               <Box>(Recommended)</Box>
            </Box>
         </Button>
         <Button
            sx={{ width: 200 }}
            color={"secondary"}
            variant={"outlined"}
            onClick={() => onSelectFlowType("CUSTOM")}
         >
            <Box>
               <TuneIcon sx={{ fontSize: "72px" }} />
               <Box>Custom</Box>
               <Box>(Advanced)</Box>
            </Box>
         </Button>
      </Box>
   );
}

export type CreationFlowType = "TEMPLATE" | "CUSTOM";
