import React from "react";
import { Box, Button } from "@mui/material";

export default function TemplateTileButton({
   text,
   icon,
   onClick,
   selected,
}: {
   text: React.ReactNode;
   icon: React.ReactNode;
   onClick: () => void;
   selected: boolean;
}) {
   return (
      <Button sx={{ width: 200 }} color={"primary"} variant={selected ? "contained" : "outlined"} onClick={onClick}>
         <Box>
            <Box sx={{ fontSize: "72px" }}>{icon}</Box>
            <Box>{text}</Box>
         </Box>
      </Button>
   );
}
