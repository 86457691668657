import React, { useEffect, useState } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import { Procedure } from "../../utils";
import { RemoteTournament } from "../useRemoteTournament";
import Checkbox from "@mui/material/Checkbox";

export default function EndTournamentConfirmDialog({
   open,
   close,
   remoteTournament,
}: {
   open: boolean;
   close: Procedure;
   remoteTournament: RemoteTournament;
}) {
   const [publishData, setPublishData] = useState<boolean>(true);
   const decklistsVisible = remoteTournament.saveRequest?.makeDecklistsVisible;
   const isPublic = remoteTournament.saveRequest?.isPublic;
   const shouldUpdate = decklistsVisible !== publishData || isPublic !== publishData;

   useEffect(() => {
      if (remoteTournament.saveRequest && shouldUpdate) {
         remoteTournament.setSaveRequest({
            ...remoteTournament.saveRequest,
            isPublic: publishData,
            makeDecklistsVisible: publishData,
         });
      }
   }, [publishData, remoteTournament, shouldUpdate]);

   return (
      <Dialog
         open={open}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         onKeyUp={(event) => {
            if (event.key === "Escape") {
               close();
            }
         }}
      >
         <DialogTitle id="alert-dialog-title">{"End tournament"}</DialogTitle>
         <DialogContent>
            {publishData && (
               <Alert severity={"success"}>
                  Tournament data and player decklists will be published on the platform! Future features, like deck
                  search, will make use of this data. Thank you for contributing!
               </Alert>
            )}
            {!publishData && (
               <Alert severity={"info"}>
                  We respect your decision. Tournament data will not be published and decklists will not be available in
                  future deck search features. If you change your mind you can edit these settings on the tournament
                  page by clicking "Edit tournament" at any time.
               </Alert>
            )}
            <FormControlLabel
               control={<Checkbox checked={publishData} onClick={() => setPublishData(!publishData)} />}
               label="Publish tournament data & decklists"
            />
         </DialogContent>
         <DialogActions>
            <Button
               onClick={async () => {
                  await remoteTournament.saveTournament();
                  await remoteTournament.end();
                  close();
               }}
               color={"secondary"}
            >
               End tournament
            </Button>
            <Button onClick={close} autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   );
}
